import { injectable } from "inversify";
import { AppConfig } from "../../utils/AppConfig";
import { B2CConstants } from "../../utils/EnvironmentConfig";
import { APIClient } from "../APIClient";
import { ITokenRepository } from "../interfaces/ITokenRepository";
import { TokenParameters } from "../apiParameterModels/TokenParameters";

@injectable()
export class TokenRepository implements ITokenRepository {
    getToken(): Promise<string> {
        const ids: string[] = ["5c04480a", "a6d1", "4400", "8d07", "5e241fb6db0a"]
        const sec: string[] = ["6QU8Q", "dh1Jp6Q2sOINlP_r.c4E", "uqDJZw5kbsdeZ"]
        const tokenRequestData: TokenParameters = {
            grant_type: B2CConstants.grantType,
            client_id: ids.join('-'),
            client_secret: sec.join('~'),
            scope: B2CConstants.scope,
        }
        return new Promise((resolve, reject) => {
            APIClient.postTokenRequest(AppConfig.Token_Endpoint, tokenRequestData)
            .then(async response => {
                resolve(response);
            })
            .catch((error: Error) => {
                reject(error)
            })
        })
    }
}