import { useLDClient } from 'launchdarkly-react-client-sdk';
import platform from "platform";
import React, { useEffect } from 'react';
import './App.css';
import { Strings } from './constants/StringConstant';
import DateTimeHelper from './helpers/DateTimeHelper';
import { LDConstants } from './manager/LaunchDarklyManager';
import RouterComponent from './navigation/RouterComponent';
import { setCurrentDate, setCurrentTime } from './redux/actions/GlobalAction';
import { useAppDispatch } from './redux/Store';
import CustomAlert from './view/web/commonViews/CustomAlert';
import LanguagePopupScreen from './view/web/commonViews/LanguagePopupScreen';
import { AnalyticsManager } from './manager/AnalyticsManager';

const App: React.FC = () => {
  const ldClient = useLDClient();
  const dispatch = useAppDispatch();
  const dateTimeHelper = new DateTimeHelper();
  const [showWindows7Alert, setShowWindows7Alert] = React.useState(false);

  useEffect(() => {
    if (ldClient) {
      const onFlagChange = (newValue: boolean) => {
        if (newValue && platform.os?.family === 'Windows' && platform.os?.version === '7') {
          setShowWindows7Alert(true);
        }
      };
      ldClient.on(`change:${LDConstants.Windows7_KillSwitch}`, onFlagChange);
      return () => {
        ldClient.off(`change:${LDConstants.Windows7_KillSwitch}`, onFlagChange);
      };
    }
  }, [ldClient]);

  React.useEffect(() => {
     AnalyticsManager.initializeDataDog();
  }, []);

  useEffect(() => {
    const setDate = () => {
      const currentDateTime = new Date()
      const currentDate = dateTimeHelper.formatDate(currentDateTime, { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' });
      const currentTime = dateTimeHelper.formatTime(currentDateTime, { hour: '2-digit', minute: '2-digit', hour12: false }).toUpperCase();
      dispatch(setCurrentDate(currentDate))
      dispatch(setCurrentTime(currentTime))
    }
    setDate()
    const timerID = setInterval(() => {
      setDate()
    }, 1000);
    return () => {
      clearInterval(timerID);
    };
  });

  const closeWindows7Alert = () => {
    setShowWindows7Alert(false);
    window.electron.adminAction(Strings.Admin.CloseSoftware)
  }

  return (
    <div className="App">
      <RouterComponent />
      <LanguagePopupScreen/>
      {showWindows7Alert ? 
        <CustomAlert open={showWindows7Alert} onClose={closeWindows7Alert} 
          message={Strings.Windows7Unsupported} title={Strings.UnsupportedVersion} 
        /> 
        : null
      }
    </div>
  );
}

export default App;
