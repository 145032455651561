
import { jwtDecode } from "jwt-decode";
import { JwtPayload } from "./apiResultModels/TokenResult";

export function getOrgErnFromAccessToken(accessToken: string): Promise<string> {
    return new Promise((resolve, reject) => {
        try {
            const decodedToken: JwtPayload = jwtDecode(accessToken);
            resolve(decodedToken.orgERN ?? '')
        } catch (error: any) {
            if (error instanceof Error) {
                reject(error)
                console.log('Error => ', error.message)
            } else {
                const _error =new Error('Error while decode the token')
                reject(_error)
            }
        }
    })
}
