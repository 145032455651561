/* HomeScreen.tsx */

import { useMediaQuery, useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiContainer } from '../../../../api/APIContainer';
import { APIRepoKeys } from '../../../../api/APIRepoKeys';
import { KioskConfigurationResult } from '../../../../api/apiResultModels/KioskConfigurationResult';
import { IKioskRepository } from '../../../../api/interfaces/IKioskRepository';
import { defaultKiosk } from '../../../../constants/DefaultValues';
import { resetToDefaultLanguage } from '../../../../helpers/CommonHelper';
import { isEqualIgnoreCase, isNonEmpty } from '../../../../helpers/StringHelper';
import { KioskUIEntity } from '../../../../models/KioskUIEntity';
import { RouterName } from '../../../../navigation/RouterName';
import { setKioskConfig } from '../../../../redux/actions/ConfigAction';
import { setAPICallInProgress } from '../../../../redux/actions/GlobalAction';
import { RootState, useAppDispatch, useAppSelector } from '../../../../redux/Store';
import { DD_APIEndPointName } from '../../../../utils/DDAPIEndpointName';
import { HomeViewModel } from '../../../../viewModels/HomeViewModel';
import { LoginViewModel } from '../../../../viewModels/LoginViewModel';
import APIFailScreen from '../../commonViews/APIFailScreen';
import Loader from '../../commonViews/Loader';
import TopBar from '../../commonViews/TopBar';
import { HomeScreenContainer } from '../../styles/StylesHome';
import HomeScreenOptions from './HomeScreenOptionsView';
import HomeScreenTopBanner from './HomeScreenTopBanner';

export interface AlertContent {
  title?: string
  message?: string
}

const kioskRepository = apiContainer.get<IKioskRepository>(
  APIRepoKeys.KIOSK_API_REPOSITORY,
);

const HomeScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const modules = useAppSelector((state) => state.configSlice.modules)
  const kioskTitle = useAppSelector((state) => state.configSlice.kioskDetails?.title ?? '')
  const kioskLogo = useAppSelector((state) => state.configSlice.kioskDetails?.kioskLogo ?? '')
  const apiCallInProgress = useSelector((state: RootState) => state.globalSlice.apiCallInProgress);
  const [lastAPICall, setLastAPICall] = React.useState<string | undefined>(undefined)
  const [alertContent, setAlertContent] = React.useState<AlertContent | undefined>(undefined)
  const [showAlert, setShowAlert] = React.useState<boolean>(false)

  /*useEffect(() => {
    AnalyticsManager.setGlobalContextforSession('AAX Custom Properties', {
      CDB: "50002",
      KioskID: "7018",
  }); // Uncomment this block to test global context
  //AnalyticsManager.logDDError('AAX error log test',{name: 'name param value', version: 25}); // Uncomment this line to test error logging
    
  });*/

  const handleCheckInAction = () => {
    navigate(RouterName.CheckIn);
  };

  const handleBookAppointmentAction = () => {
    navigate(RouterName.BookAppointment);
  };

  const siteMapAction = () => {
    navigate(RouterName.SiteMap);
  };

  const questionnairesAction = () => {
    navigate(RouterName.Questionnaire, { state: { from: `${RouterName.Home}` } });
  };

  const theme = useTheme();
  const portrait = useMediaQuery('(orientation: portrait)');
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const allFlags = useFlags();
  console.log('=======allFlags------', allFlags);

  const fetchKioskConfig = React.useCallback(async () => {
    const selectedKiosk = JSON.stringify(defaultKiosk) //localStorage.getItem(LocalStorageKey.SelectedKiosk) ?? '';
    let selectedKioskEntity: KioskUIEntity | undefined = undefined
    if (isNonEmpty(selectedKiosk)) {
      selectedKioskEntity = JSON.parse(selectedKiosk)
    }
    if (selectedKioskEntity?.id && isNonEmpty(selectedKioskEntity.id)) {
      setLastAPICall(DD_APIEndPointName.kioskConfiguration_API_Endpoint)
      dispatch(setAPICallInProgress(true));
      try {
        const responseString = await kioskRepository.getKioskConfiguration(`${selectedKioskEntity.id}`);
        const kioskConfigurationResult: KioskConfigurationResult = JSON.parse(responseString);
        dispatch(setKioskConfig(kioskConfigurationResult))
        resetToDefaultLanguage(dispatch, HomeViewModel().getLanguages())
        dispatch(setAPICallInProgress(false));
      }
      catch (error) {
        console.log('error => ', error)
        dispatch(setAPICallInProgress(false));
        setAlertContent({ title: 'Failed', message: `Failed to get configuration for the kiosk "${selectedKioskEntity.title}"` })
        setShowAlert(true)
      }
    } else {
      navigate(RouterName.KioskList)
    }
  }, [dispatch, navigate])

  const validateAndFetchToken = React.useCallback(async () => {
    const languages = HomeViewModel().getLanguages();
    resetToDefaultLanguage(dispatch, languages);

    setLastAPICall(DD_APIEndPointName.Token_API_Endpoint)
    dispatch(setAPICallInProgress(true));
    LoginViewModel().validateSession(dispatch)
      .then(isValidSession => {
        dispatch(setAPICallInProgress(false));
        if (!isValidSession) {
          setAlertContent({ title: 'Failed', message: `Failed to fetch Token` })
          setShowAlert(true)
        } else {
          fetchKioskConfig()
        }
      })
      .catch(error => {
        console.log('error => ', error)
        dispatch(setAPICallInProgress(false));
        setAlertContent({ title: 'Failed', message: `Failed to get Token` })
        setShowAlert(true)
      })
  }, [dispatch, fetchKioskConfig])

  React.useEffect(() => {
    const languages = HomeViewModel().getLanguages();
    resetToDefaultLanguage(dispatch, languages);
    validateAndFetchToken()
  }, [validateAndFetchToken, dispatch])

  function handleRetryAction() {
    setShowAlert(false)
    if (isEqualIgnoreCase(lastAPICall, DD_APIEndPointName.kioskConfiguration_API_Endpoint)) {
      fetchKioskConfig()
    } else if (isEqualIgnoreCase(lastAPICall, DD_APIEndPointName.Token_API_Endpoint)) {
      validateAndFetchToken()
    }
  }

  return (
    <HomeScreenContainer>

      <TopBar
        isShowHomeButton={false}
        isShowBackOption={false}
        handleBackClick={() => { }}
        timeOutMessage=''
      />

      <HomeScreenTopBanner kioskTitle={kioskTitle} kioskLogo={kioskLogo} />

      {!showAlert ?
        <HomeScreenOptions
          handleCheckInAction={handleCheckInAction}
          handleBookAppointmentAction={handleBookAppointmentAction}
          siteMapAction={siteMapAction}
          questionnairesAction={questionnairesAction}
          modules={modules}
          portrait={portrait}
          mobile={mobile}
        />
        : null
      }

      {showAlert && alertContent ?
        <APIFailScreen
          title={alertContent?.title ?? ''}
          information={alertContent?.message ?? ""}
          closeAction={() => {
            setAlertContent(undefined)
            setShowAlert(false)
          }}
          tryAgainAction={handleRetryAction}
          isShowCloseAction={false}
        />
        : null
      }

      {apiCallInProgress ? <Loader showLoading={apiCallInProgress} /> : null}

    </HomeScreenContainer>
  );
};

export default HomeScreen;
