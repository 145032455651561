import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Strings } from '../../../../constants/StringConstant';
import useIdleTimer from '../../../../customHooks/useIdleTimer';
import { AdminActionType } from '../../../../enum';
import { getScreenTimeOutInMilliSeconds } from '../../../../helpers/CommonHelper';
import { RouterName } from '../../../../navigation/RouterName';
import { store, useAppDispatch } from '../../../../redux/Store';
import MessageBanner from "../../commonViews/MessageBanner";
import TopBar from "../../commonViews/TopBar";
import AdminOptionsScreen from './AdminOptionsScreen';
import AdminPinView from './AdminPinView';
import { AdminContainerScreenView } from './StylesAdminScreen';

const AdminContainerScreen: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isShowPinView, setIsShowPinView] = React.useState<boolean>(true)
    const [showValidationMessage, setShowValidationMessage] = React.useState<boolean>(false)
    const [bannerMessage, setBannerMessage] = React.useState<string>(t(Strings.Admin.AdminLogin))

    function validatePin(pin: string) {
        const adminPassword = store.getState().configSlice.kioskDetails?.adminPassword ?? ''
        if (pin === adminPassword) {
            setIsShowPinView(false)
            setShowValidationMessage(false)
            setBannerMessage(t(Strings.Admin.AdminArea))
        } else {
            setShowValidationMessage(true)
            setBannerMessage(t(Strings.Admin.IncorrectPin))
        }
    }

    const dispatch = useAppDispatch();
    const screenTimeOut = store.getState().configSlice.kioskDetails?.screenTimeOut
    const { isIdle, timeRemaining } = useIdleTimer(getScreenTimeOutInMilliSeconds(screenTimeOut), dispatch, navigate);
    const [adminScreenTimeOutMessage, setAdminScreenTimeOutMessage] = React.useState<string>('')

    React.useEffect(() => {
        if (isIdle && timeRemaining === 1000) {
            setAdminScreenTimeOutMessage(`${t(Strings.TimingOutIn)} ${timeRemaining / 1000}`)
            setTimeout(() => {
                setAdminScreenTimeOutMessage('')
            }, 1000);
        } else if (isIdle && timeRemaining && timeRemaining <= 3000) {
            setAdminScreenTimeOutMessage(`${t(Strings.TimingOutIn)} ${timeRemaining / 1000}`)
        } else {
            setAdminScreenTimeOutMessage('')
        }
    }, [dispatch, isIdle, navigate, t, timeRemaining])

    function handleAdminAction(action: AdminActionType) {
        if (window.electron) {
            switch (action) {
                case AdminActionType.CloseSoftware:
                    window.electron.adminAction(Strings.Admin.CloseSoftware)
                    return
                case AdminActionType.RebootSystem:
                    window.electron.adminAction(Strings.Admin.RebootSystem)
                    return
                case AdminActionType.ShutdownSystem:
                    window.electron.adminAction(Strings.Admin.ShutdownSystem)
                    return
                default:
                    console.log("Unhandled Admin action")
            }
        } else {
            console.log("window.electron is undefined")
        }
    }

    return (
        <AdminContainerScreenView>
            <TopBar
                isShowHomeButton={false}
                isShowBackOption={true}
                handleBackClick={() => navigate(RouterName.Home)}
                timeOutMessage={adminScreenTimeOutMessage}
            />
            <MessageBanner message={bannerMessage} showValidationMessage={showValidationMessage} />
            {isShowPinView ?
                <AdminPinView onCompletePinEntry={validatePin} />
                :
                <AdminOptionsScreen
                    handleAdminAction={handleAdminAction}
                />
            }
        </AdminContainerScreenView>
    )
}

export default AdminContainerScreen
