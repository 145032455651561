import { Button, styled } from "@mui/material";
import { ColorConstant } from "../../../constants/ColorConstant";

/* QuestionnaireContainer */
export const QuestionnaireContainerView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ColorConstant.WHITE,
    height: '100vh',
    width: '100vw',
    userSelect: 'none'
});

export const QuestionnaireContainerTopView = styled('div')({
    backgroundColor: ColorConstant.WHITE,
});

/* QuestionnaireListScreen */
export const QuestionnaireListContainerView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ColorConstant.WHITE,
    height: '100vh',
    width: '100vw',
    userSelect: 'none',
    overflow: 'hidden',
});

export const QuestionnaireListInnerContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    margin: '2% 5%',
    marginRight: '10%',
    backgroundColor: ColorConstant.WHITE,
    alignItems: 'center',
    overflow: 'hidden',
});

export const QuestionnaireListView = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
    marginTop: 20,
})

export const QuestionnaireListButton = styled(Button)({
    width: '100%',
    padding: '10px 20px',
    border: `none`,
    backgroundColor: ColorConstant.EMERALD_STONE,
    marginBottom: 10,
    color: ColorConstant.WHITE,
    textTransform: 'none',
    textAlign: 'left',
    fontSize: 'larger',
    justifyContent: 'left',
    '&:hover': {
        backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    },
});

/* QuestionnaireScreen */
export const QuestionnaireScreenContainerView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ColorConstant.WHITE,
    height: '100vh',
    width: '100vw',
    userSelect: 'none',
    overflow: 'hidden',
});

export const QuestionnaireScreenTopView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    margin: '30px 5%',
    overflow: 'hidden',
})

/* QuestionnaireScreen OptionsView */
export const QuestionnaireScreenOptionsView = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginBottom: 20,
    padding: '0% 10%',
    alignItems: 'center',
    overflow: 'auto',
})

export const QuestionOptionButton = styled(Button)(({ theme }) => ({
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    textAlign: 'left',
    fontSize: 'larger',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    borderRadius: 5,
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE,
    },
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
        width: '80%',
        marginBottom: 10,
        fontSize: 'large',
        padding: 10,
    },
    [theme.breakpoints.up('md')]: {
        width: '40%',
        marginBottom: 20,
        fontSize: 'larger',
        padding: 20,
    },
}));

/* QuestionnaireScreen TextView */
export const QuestionnaireScreenTextView = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'left',
    overflow: 'auto',
    justifyContent: 'space-between',
})

export const QuestionnaireScreenTextInnerView = styled('div')({
    padding: '0% 5%',
})

export const QuestionContinueButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    textAlign: 'left',
    margin: '20px 0px',
    fontSize: 'larger',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    borderRadius: 5,
    padding: '10px 30px',
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE,
    },
    '&:disabled': {
        backgroundColor: ColorConstant.GREY,
        color: 'white',
    },
    '&:active': {
        backgroundColor: ColorConstant.EMERALD_STONE,
    },
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
        fontSize: 'large',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 'larger',
    },
}));

export const QuestionnaireKeyboardContainerView = styled('div')({
    display: 'flex',
    backgroundColor: 'black',
    paddingTop: 10,
    width: '100%',
    justifyContent: 'center',
})

export const KeyboardContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    backgroundColor: 'black',
    alignSelf: 'center',
});

export const QuestionnaireTextView = styled('div')(({ theme }) => ({
    justifyContent: 'flex-start',
    height: '10vh',
    width: '100%',
    border: `2px solid ${ColorConstant.CLOUDED_VISION}`,
    textAlign: 'left',
    padding: 20,
    backgroundColor: ColorConstant.WHITE,
    [theme.breakpoints.down('md')]: {
        fontSize: 'large'
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 'larger'
    },
    wordWrap: 'break-word',
}));

export const KeyboardTextContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    maxWidth: '70%',
    margin: 20,
});

export const NumberPadContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    maxWidth: '20%',
    margin: 'auto',
});

export const KeyButton = styled(Button)({
    height: 45,
    padding: `5px 10px`,
    margin: '5px',
    fontSize: 'larger',
    gridColumn: 'auto',
    backgroundColor: ColorConstant.WHITE,
    color: ColorConstant.IRON,
    '&:hover': {
        backgroundColor: ColorConstant.GREY,
        color: ColorConstant.WHITE,
    },
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    textTransform: 'none',
});

export const EmptyKey = styled('div')({
    
});

export const DeleteKeyTextButton = styled(Button)({
    height: 45,
    padding: `5px 10px`,
    margin: '5px',
    fontSize: 'larger',
    gridColumn: 'auto',
    backgroundColor: ColorConstant.WHITE,
    color: ColorConstant.IRON,
    '&:hover': {
        backgroundColor: ColorConstant.RED_POWER,
        color: ColorConstant.WHITE,
    },
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
});

/* QuestionnaireSuccessScreen */
export const QuestionnaireSuccessScreenContainerView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ColorConstant.WHITE,
    height: '100vh',
    userSelect: 'none',
    overflow: 'hidden',
    padding: '1% 20%',
});

export const QuestionnaireSuccessInfoView = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
});

export const QuestionnaireSuccessTankYouText = styled('div')({
    color: ColorConstant.IRON,
    fontSize: 'xxx-large',
    fontWeight: 'bold',
    padding: '20px',
});

export const QuestionnaireSuccessTankYouSubText = styled('div')({
    color: ColorConstant.IRON,
    fontSize: 'xx-large',
    fontWeight: '500',
    paddingBottom: '15px',
});

export const QuestionnaireSuccessTankYouDescriptionText = styled('div')({
    color: ColorConstant.IRON,
    fontSize: 'x-large',
});

export const CloseButton = styled(Button)({
    width: `100%`,
    height: 80,
    marginBottom: 30,
    color: ColorConstant.WHITE,
    border: `none`,
    borderRadius: 5,
    fontSize: 'x-large',
    fontWeight: 'bold',
    textTransform: 'none',
    justifyContent: 'center',
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE,
    },
});