import { store } from "../redux/Store";

export const getAccessToken = () => {
    return store.getState().tokenSlice.accessToken;
};

export const getAdminAccessToken = () => {
  return store.getState().tokenSlice.adminAccessToken;
};

export const BaseURL = {
  openAPI: '',
  token: 'https://identity.int.emishealthsolutions.com/',
  certificate: 'https://idinternal.emishealth.com/enrolment/certificates/automatedArrivals',
  emisXBaseURL: '',
} as const