import { Language } from "../api/apiResultModels/KioskConfigurationResult";
import { defaultLanguages } from "../constants/DefaultValues";
import { MatchTitle } from "../enum";
import { isEqualIgnoreCase } from "../helpers/StringHelper";
import { store } from "../redux/Store";

export const HomeViewModel = () => {

    function getLanguages(): Language[] {
        return store.getState().configSlice.languages ?? defaultLanguages;
    }

    function showDate(currentMatchTitle: MatchTitle | undefined,) {
        return (isEqualIgnoreCase(currentMatchTitle, MatchTitle.Date) || isEqualIgnoreCase(currentMatchTitle, MatchTitle.Date_1))
    }

    function showSurName(currentMatchTitle: MatchTitle | undefined,) {
        return (isEqualIgnoreCase(currentMatchTitle, MatchTitle.SurName) || isEqualIgnoreCase(currentMatchTitle, MatchTitle.SurName_1))
    }

    return {
        getLanguages,
        showDate,
        showSurName,
    }
}