import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { Button, styled } from "@mui/material";
import { ColorConstant } from "../../../constants/ColorConstant";

/* CheckInContainer */
export const CheckInContainerView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ColorConstant.WHITE,
    height: '100vh',
    width: '100vw',
    userSelect: 'none'
});

export const CheckInContainerTopView = styled('div')({
    backgroundColor: ColorConstant.WHITE,
});

export const CheckInContainerScrollableView = styled('div')({
    overflowY: 'auto',
});

/* AppointmentListScreen */
export const AppointmentListScreenContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: `calc(100vh - 20px)`,
    padding: `10px 10%`,
    overflow: "hidden",
    userSelect: 'none',
});

export const AppointmentListScreenHeader = styled('div')({
    display: 'flex',
    height: 80,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
});

export const UserNameContainer = styled('div')({
    display: 'flex',
    flex: 1,
    gap: 10,
    alignSelf: 'center',
});

export const HiText = styled('span')({
    fontSize: 'xx-large',
    fontWeight: 'normal',
    color: ColorConstant.IRON,
});

export const UserNameText = styled('span')({
    fontSize: 'xx-large',
    fontWeight: 'bold',
    color: ColorConstant.IRON,
    textAlign: 'left',
});

export const NotYouButton = styled(Button)({
    backgroundColor: ColorConstant.WHITE,
    border: `5px solid ${ColorConstant.CERAMIC_BLUE_TURQUOISE}`,
    borderRadius: '5px',
    minWidth: 200,
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    alignSelf: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    textTransform: 'none',
});

export const AppointmentListScreenHeaderDivider = styled('div')({
    height: 2,
    backgroundColor: ColorConstant.WHITE_EDGAR
});

export const SingleAppointmentContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

export const SingleAppointmentText = styled('span')({
    fontSize: 'x-large',
    fontWeight: 'normal',
    color: ColorConstant.IRON,
    marginRight: 10,
});

export const SingleAppointmentTimeText = styled('span')({
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: ColorConstant.IRON
});

export const SingleAppointmentWithText = styled('span')({
    fontSize: 'x-large',
    fontWeight: 'normal',
    color: ColorConstant.IRON,
    margin: '30px 10px',
});

export const SingleAppointmentSessionHolderNameText = styled('span')({
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: ColorConstant.IRON
});

export const AppointmentListContentView = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    overflow: 'hidden'
});

export const AppointmentListSelectAppointmentText = styled('div')({
    alignSelf: 'flex-start',
    margin: '10px 0px',
    color: ColorConstant.IRON,
    paddingTop: 10,
    fontSize: 'x-large',
    fontWeight: '500',
});

export const SelectAppointmentListView = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '10px 0px',
});

export const FooterButtonView = styled('div')({
    height: 80,
    flexDirection: 'column',
    paddingTop: 10,
    paddingBottom: 30,
});

export const CheckInButton = styled(Button)<{ isDisabled: boolean }>(({ isDisabled }) => ({
    width: `100%`,
    height: `100%`,
    color: ColorConstant.WHITE,
    border: `none`,
    borderRadius: '5px',
    fontSize: 'xx-large',
    fontWeight: 'bold',
    textTransform: 'none',
    backgroundColor: isDisabled ? ColorConstant.IRON : ColorConstant.EMERALD_STONE,
    '&:hover': {
        backgroundColor: isDisabled ? ColorConstant.IRON : ColorConstant.EMERALD_STONE,
    },
}));

export const CloseButton = styled(Button)({
    width: `100%`,
    height: `100%`,
    backgroundColor: ColorConstant.BLOSSOMING_DYNASTY,
    color: ColorConstant.WHITE,
    border: `none`,
    borderRadius: '5px',
    fontSize: 'larger',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: ColorConstant.BLOSSOMING_DYNASTY,
    },
    textTransform: 'none',
});

/* AppointmentItem */
export const AppointmentItemContainerButton = styled(Button)({
    display: 'flex',
    padding: 10,
    width: '100%',
    border: 'none',
    margin: 5,
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    textTransform: 'none',
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE,
    },
});

export const AppointmentItemTextView = styled('div')({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 5,
});

export const AppointmentItemTimeText = styled('div')({
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: ColorConstant.WHITE,
});

export const AppointmentItemWithText = styled('div')({
    fontSize: 'x-large',
    fontWeight: 'normal',
    color: ColorConstant.WHITE,
});

export const AppointmentItemSessionHolderNameText = styled('div')({
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: ColorConstant.WHITE,
});

export const NoAppointmentItemContainerView = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    margin: 10,
    userSelect: 'none',
    background: ColorConstant.WHITE,
});

export const NoAppointmentItemTextView = styled('div')({
    margin: '0px 10px'
});

export const NoAppointmentItemTextTopView = styled('div')({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 5,
});

export const NoAppointmentItemTimeText = styled('div')({
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: ColorConstant.RED_POWER,
});

export const NoAppointmentItemWithText = styled('div')({
    fontSize: 'x-large',
    fontWeight: 'normal',
    color: ColorConstant.RED_POWER,
});

export const NoAppointmentItemSessionHolderNameText = styled('div')({
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: ColorConstant.RED_POWER,
});

export const NoAppointmentItemDescriptionText = styled('div')({
    fontSize: 'larger',
    fontWeight: 'normal',
    color: ColorConstant.RED_POWER,
    textAlign: 'left',
});

/* CheckInSuccessScreen */
export const CheckInSuccessScreenContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    height: `100vh`,
    width: '100%',
    backgroundColor: 'white',
    alignItems: 'center',
    overflow: 'hidden',
    userSelect: 'none',
});

export const CheckInSuccessScreenInnerContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    margin: `0% 2%`,
    height: `100%`,
});

export const DirectionalMessagesContainerView = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'auto',
});

export const CheckInSuccessScreenUserNameContainer = styled('div')({
    display: 'flex',
    flex: 1,
    gap: `10px`,
    alignSelf: 'center',
    marginTop: `2%`,
});

export const CheckInSuccessScreenHiText = styled('span')({
    fontSize: 'xx-large',
    fontWeight: 'normal',
    color: ColorConstant.IRON,
});

export const CheckInSuccessScreenUserNameText = styled('span')({
    fontSize: 'xx-large',
    fontWeight: 'bold',
    color: ColorConstant.IRON,
});

export const CheckInSuccessScreenTitleView = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    margin: '2% 0%',
    marginBottom: '0%'
});

export const StylesCheckCircleOutlineRoundedIcon = styled(CheckCircleOutlineRoundedIcon)({
    color: ColorConstant.GREEN_SEDUCTION,
    fontSize: 55,
});

export const CheckInSuccessScreenTitleText = styled('div')({
    textAlign: 'left',
    color: ColorConstant.BLACK_SPACE,
    fontSize: 'xx-large',
    fontWeight: 'bold',
    marginLeft: '20px',
    alignSelf: 'center',
});

export const CheckInSuccessScreenDivider = styled('div')({
    display: 'flex',
    backgroundColor: ColorConstant.DESIRED_DAWN,
    height: '2px',
    alignSelf: 'center',
    width: '100%',
    border: 'none',
    borderRadius: '5px',
    margin: '20px 0px',
});

export const DirectionalMessagesViewContainer = styled('div')({
    paddingTop: `20px`,
    paddingBottom: `20px`,
    marginLeft: `50px`,
});

export const DirectionalMessageText = styled('div')({
    textAlign: 'left',
    color: ColorConstant.BLACK_SPACE,
    fontSize: 'xx-large',
    fontWeight: 'bold',
    marginLeft: '20px',
    alignSelf: 'center',
});

export const CheckInSuccessScreenSingleSlotContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    backgroundColor: ColorConstant.BELUGA,
    padding: '10px',
    margin: '10px',
    width: '95%',
});

export const CheckInSuccessScreenSingleSlotInnerContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: 10,
});

export const SingleSlotTimeText = styled('span')({
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: ColorConstant.IRON,
});

export const SingleSlotWithText = styled('span')({
    fontSize: 'x-large',
    fontWeight: 'normal',
    color: ColorConstant.IRON,
});

export const SingleSlotSessionHolderNameText = styled('span')({
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: ColorConstant.IRON,
});

export const SingleSlotOnTimeText = styled('div')({
    fontSize: 'x-large',
    fontWeight: 'normal',
    color: ColorConstant.IRON,
    marginRight: '0px',
});

export const CheckInSuccessScreenMultiSlotsView = styled('div')({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
});

export const CheckInSuccessScreenFooterView = styled('div')({
    height: '80px',
    flexDirection: 'column',
    margin: '2% 0%',
});

export const CheckInSuccessScreenFooterButton = styled(Button)({
    width: `100%`,
    height: `100%`,
    backgroundColor: ColorConstant.EMERALD_STONE,
    color: ColorConstant.WHITE,
    border: `none`,
    borderRadius: '5px',
    fontSize: 'x-large',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: ColorConstant.BLUE_TUNA,
    },
    textTransform: 'none',
});

export const CheckInSuccessScreenMessageContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '30%',
    height: '100%',
    backgroundColor: ColorConstant.BELUGA,
    overflow: 'hidden',
});

export const CheckInSuccessMessageVerticalLine = styled('div')({
    backgroundColor: ColorConstant.BELUGA,
    width: '5px',
    height: '100%',
});

export const CheckInSuccessScreenMessageInnerContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginLeft: '-10px',
    overflow: 'hidden',
});

export const CheckInSuccessScreenMessageTitleText = styled('div')({
    padding: '20px 30px',
    fontSize: '20px',
    fontWeight: 'bold',
    color: ColorConstant.IRON,
    textAlign: 'left',
});

export const CheckInSuccessScreenMessagesContainer = styled('div')({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
});

export const MessageItemView = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0px'
});

export const CheckInSuccessMessageInnerVerticalLine = styled('span')({
    backgroundColor: ColorConstant.TROLLEY_GREY,
    width: '10px',
    height: '100%',
});

export const CheckInSuccessMessageText = styled('span')({
    flex: 1,
    textAlign: 'left',
    color: ColorConstant.IRON,
    padding: '5px 15px',
});


/* PatientInfoScreen */
export const PatientDemographicScreenContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: 'calc(100vh - 20px)',
    padding: '10px 5%',
    overflow: 'hidden',
    userSelect: 'none',
});

export const PatientInfoScreenHeader = styled('div')({
    height: '60px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
});

export const PatientInfoScreenTopContainer = styled('div')({
    display: 'flex',
    height: '80px',
    gap: '5px',
    flex: 1
});

export const PatientInfoScreenUserNameContainer = styled('div')({
    display: 'flex',
    flex: 1,
    gap: '5px',
    alignSelf: 'center'
});

export const ThanksText = styled('span')({
    fontSize: '2em',
    fontWeight: 'normal',
    color: ColorConstant.IRON,
    marginRight: '5px'
});

export const PatientNameText = styled('span')({
    fontSize: '2em',
    fontWeight: 'bold',
    color: ColorConstant.IRON
});

export const PatientInfoScreenDivider = styled('div')({
    height: '2px',
    backgroundColor: ColorConstant.WHITE_EDGAR
});

/* Patient Personal Info */
export const PatientPersonalInfoViewContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifySelf: 'center',
    alignContent: 'center',
});

export const PatientPersonalInfoViewTitleText = styled('div')({
    fontSize: '20px',
    fontWeight: 600,
    color: ColorConstant.IRON,
    paddingTop: '20px',
    textAlign: 'left',
});

export const PatientPersonalInfoViewSubTitleText = styled('div')({
    fontSize: '20px',
    fontWeight: 500,
    color: ColorConstant.TROLLEY_GREY,
    padding: '10px 0px',
    textAlign: 'left',
});

export const PatientPersonalInformationView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: ColorConstant.Spindrift,
    border: `2px solid ${ColorConstant.BrushedMetal}`,
    margin: '0px',
    padding: '10px 0px'
});

export const PatientPersonalInformationInnerView = styled('div')({
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
});

export const PatientInfoTitleText = styled('div')({
    width: '50%',
    textAlign: 'right',
    fontWeight: 500,
    color: ColorConstant.TROLLEY_GREY,
    fontSize: '20px'
});

export const PatientInfoValueText = styled('div')({
    width: '50%',
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '20px',
    color: ColorConstant.IRON
});

/* FooterView */
export const PatientInfoFooterView = styled('div')({
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
});

export const PatientInfoFooterViewTitleText = styled('div')({
    fontSize: '2em',
    fontWeight: 'bold',
    paddingBottom: '10px',
    color: ColorConstant.IRON
});

export const PatientInfoFooterViewButtonView = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '15px 0px'
});

export const PatientInfoYesButton = styled(Button)({
    backgroundColor: ColorConstant.EMERALD_STONE,
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '20px 0px',
    margin: '0% 2%',
    cursor: 'pointer',
    fontSize: '1.5em',
    transition: 'background-color 0.3s',
    width: '35%',
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE
    },
    textTransform: 'none',
});

export const PatientInfoNoButton = styled(Button)({
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '20px 0px',
    margin: '0% 2%',
    cursor: 'pointer',
    fontSize: '1.5em',
    transition: 'background-color 0.3s',
    width: '35%',
    '&:hover': {
        backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE
    },
    textTransform: 'none',
});

export const PatientInfoScreenAlertContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    boxSizing: 'border-box',
    overflow: 'hidden'
});

export const PatientInfoScreenAlertTextContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center'
});

export const PatientInfoScreenAlertText1 = styled('div')({
    color: ColorConstant.IRON,
    fontSize: '30px',
    padding: '20px 0px',
});

export const PatientInfoScreenAlertText2 = styled('div')({
    color: ColorConstant.IRON,
    fontSize: '30px',
    padding: '20px 0px',
    fontWeight: 'bold'
});

export const PatientInfoScreenCheckInButton = styled(Button)({
    height: '12%',
    backgroundColor: ColorConstant.EMERALD_STONE,
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    fontSize: '25px',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE
    },
    textTransform: 'none',
    margin: '50px 0px'
});
