
import { Button, styled, Typography } from "@mui/material";
import { ColorConstant } from "../../../../constants/ColorConstant";
import { SizeConstant } from "../../../../constants/SizeConstant";

export const AdminContainerScreenView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ColorConstant.WHITE,
    height: '100vh',
    width: '100vw',
    userSelect: 'none'
});

export const AdminPinContainerView = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: ColorConstant.WHITE,
});

export const AdminPinInnerContainerView = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: ColorConstant.WHITE,
    padding: '0 5vw'
});

export const EnterPinText = styled('div')({
    justifyContent: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    padding: 20,
    paddingBottom: 0,
    color: ColorConstant.IRON,
});

export const PinView = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    margin: '40px',
    marginTop: '50px',
    gap: '20px',
});

export const KeyboardContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    userSelect: 'none'
});

export const EmptyCircle = styled('div')({
    width: '20px',
    aspectRatio: '1/1',
    border: `2px solid ${ColorConstant.CERAMIC_BLUE_TURQUOISE}`,
    borderRadius: '50%',
    backgroundColor: 'transparent'
});

export const FilledCircle = styled(EmptyCircle)({
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
});

export const AdminOptionsScreenContainer = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: ColorConstant.BELUGA,
});

export const TechnicalInformationText = styled('div')({
    justifyContent: 'center',
    fontSize: '2rem',
    fontWeight: 'bold',
    padding: 20,
    paddingBottom: 0,
    color: ColorConstant.IRON,
});

export const AdminFooterContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    margin: '50px 0',
    marginBottom: 20,
});

export const AdminFooterInnerContainer = styled('div')({
    display: 'flex',
    gap: '5vw'
});

export const AdminFooterButton = styled(Button)({
    height: `${SizeConstant.Admin.FooterButton.gridHeight}px`,
    width: `25vw`,
    minWidth: `${SizeConstant.Admin.FooterButton.gridWidth}px`,
    maxWidth: `300px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.3rem',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: ColorConstant.CERAMIC_BLUE_TURQUOISE,
    borderRadius: 5,
    '&:hover': {
        backgroundColor: ColorConstant.EMERALD_STONE,
    },
    textTransform: 'none',
});

export const CloseSoftwareTextTypography = styled(Typography)({
    height: `${SizeConstant.Admin.FooterButton.gridHeight}px`,
    width: `25vw`,
    minWidth: `${SizeConstant.Admin.FooterButton.gridWidth}px`,
    maxWidth: `300px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.3rem',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: 'transparent',
    borderRadius: 5,
    textTransform: 'none',
    '& .closeSoftware-text': {
        fontWeight: 'bold',
    },
    '& .asterisk-text': {
        verticalAlign: 'top',
        fontSize: '2rem',
    },
});

export const AdminFooterDisclaimerTypography = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: ColorConstant.IRON,
    borderRadius: 5,
    textTransform: 'none',
    '& .closeSoftware-text': {
        fontWeight: 'bold',
    },
    '& .asterisk-text': {
        verticalAlign: 'top',
        fontSize: '2rem',
    },
});

export const KioskInformationContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: ColorConstant.Spindrift,
    border: `2px solid ${ColorConstant.BrushedMetal}`,
    marginTop: '30px',
    padding: '10px 0px'
});

export const KioskInformationView = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    gap: '10px',
});

export const KioskInfoTitleText = styled('div')({
    width: '30%',
    textAlign: 'right',
    fontWeight: 500,
    color: ColorConstant.TROLLEY_GREY,
    fontSize: '1.3rem',
});

export const KioskInfoValueText = styled('div')({
    display: 'flex',
    flex: 1,
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '1.3rem',
    color: ColorConstant.IRON,
});