import { BaseURL, getAccessToken } from "../utils/ApiRequestUtil";
import { DD_APIEndPointName } from "../utils/DDAPIEndpointName";
import { getOrgErnFromAccessToken } from "./AccessTokenMapper";

export class APIClient {

    static getRequest(apiName: DD_APIEndPointName, url: string, accessToken: string, params?: any): Promise<string> {

        let apiURL = `${BaseURL.emisXBaseURL}${url}`
        if (params) {
            const queryParams = new URLSearchParams(params).toString();
            apiURL = `${apiURL}?${queryParams}`
        }
        return fetch(apiURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken,
            },
        })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                const responseString = await response.text()
                return responseString;
            })
            .catch(error => {
                console.error('GET request failed:', error);
                throw error;
            });
    }

    static postTokenRequest(url: string, data: any): Promise<string> {
        const apiURL = `${BaseURL.token}${url}`
        const formBody = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');

        return fetch(apiURL, {
            method: 'POST',
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formBody,
        })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                const responseString = await response.text()
                return responseString;
            })
            .catch(error => {
                console.error('POST request failed:', error);
                throw error;
            });
    }

    static postRequest(apiName: DD_APIEndPointName, url: string, data: any): Promise<any> {
        const apiURL = `${BaseURL.openAPI}${url}`

        return fetch(apiURL, {
            method: 'POST',
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getAccessToken()}`,
                'TargetOrganisationGuid': 'd5753427-76ec-48e8-ab19-7f3308d5ca3d',
            },
            body: JSON.stringify(data),
        })
            .then(async response => {
                if (response.status === 200) {
                    const responseText = await response.text()
                    console.log(`${apiName} Response => `, responseText)
                    return responseText;
                }
                throw new Error(`Error: ${response.status}`);
            })
            .catch(error => {
                console.error('POST request failed:', error);
                throw error;
            });
    }

    static async postEnrolKioskRequest() {
        try {
            const apiURL = `${BaseURL.certificate}`
            const accessToken = getAccessToken()
            const orgErn = await getOrgErnFromAccessToken(accessToken)
            const responseString = await window.electron.postEnrolKioskRequest(apiURL, accessToken, orgErn)
            return responseString
        }
        catch (error) {
            console.error('POST Enrollment Certificate failed:', error);
            throw error;
        }
    }
}