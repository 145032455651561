import { Strings } from "../constants/StringConstant"
import { DateType, MatchTitle } from "../enum"
import { isEmpty } from "../helpers/StringHelper"
import { DateComponents } from "../view/web/screens/matchingScreen/dob/DateComponent"

export interface BannerComponents {
    headerText: string
    subHeaderText: string
}

export const MatchingViewModel = () => {

    const constructDateComponent = (date: string, separator: string): DateComponents => {
        const components = date.split(separator)
        let dateComponent: string;
        if (components.length > 0) {
            dateComponent = isEmpty(components[0]) ? DateType.DD : components[0];
        } else {
            dateComponent = DateType.DD;
        }

        let monthComponent: string;
        if (components.length > 1) {
            monthComponent = isEmpty(components[1]) ? DateType.MM : components[1];
        } else {
            monthComponent = DateType.MM;
        }

        let yearComponent: string;
        if (components.length > 2) {
            yearComponent = isEmpty(components[2]) ? DateType.YYYY : components[2];
        } else {
            yearComponent = DateType.YYYY;
        }

        return { date: dateComponent, month: monthComponent, year: yearComponent }
    }

    const getBannerText = (currentMatchTitle: MatchTitle): BannerComponents => {
        switch (currentMatchTitle) {
            case MatchTitle.Date:
            case MatchTitle.Date_1:
                return { headerText: Strings.Matching.WhatIsYourDateOfBirth, subHeaderText: Strings.Matching.DateOfBirthExample }

            case MatchTitle.Day:
                return { headerText: Strings.Matching.WhatDayOfTheMonthWereYouBorn, subHeaderText: '' }

            case MatchTitle.Month:
                return { headerText: Strings.Matching.WhatMonthWereYouBorn, subHeaderText: '' }

            case MatchTitle.Year:
                return { headerText: Strings.Matching.WhatYearWereYouBorn, subHeaderText: '' }

            case MatchTitle.SurName:
            case MatchTitle.SurName_1:
                return { headerText: Strings.Matching.WhatIsTheFirstLetterOfYourSurname, subHeaderText: '' }

            case MatchTitle.Gender:
                return { headerText: Strings.Matching.AreYou, subHeaderText: '' }

            case MatchTitle.PostalCode:
                return { headerText: Strings.Matching.WhatIsYourPostalCode, subHeaderText: '' }

            default:
                return { headerText: '', subHeaderText: '' }
        }
    }

    function getMonths() {
        return [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ]
    }

    const getYears = (): string[] => {
        const currentYear = new Date().getFullYear();
        const yearsArray = Array.from(
            { length: 101 },
            (_, i) => (currentYear - i).toString()
        );
        return yearsArray
    }

    return {
        constructDateComponent,
        getBannerText,
        getMonths,
        getYears,
    }
}