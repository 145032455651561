import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Strings } from '../../../../constants/StringConstant';
import { AdminActionType } from '../../../../enum';
import { useAppSelector } from '../../../../redux/Store';
import { AdminFooterButton, AdminFooterContainer, AdminFooterDisclaimerTypography, AdminFooterInnerContainer, AdminPinContainerView, AdminPinInnerContainerView, CloseSoftwareTextTypography, KioskInfoTitleText, KioskInfoValueText, KioskInformationContainer, KioskInformationView, TechnicalInformationText } from './StylesAdminScreen';

export interface AdminOptionsScreenProps {
    handleAdminAction: (action: AdminActionType) => void
}

const AdminOptionsScreenContainer: React.FC<AdminOptionsScreenProps> = (props: AdminOptionsScreenProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const kioskDetails = useAppSelector((state) => state.configSlice.kioskDetails)
    const kioskInfo = [
        {
            id: 0,
            label: 'Software Version',
            text: kioskDetails?.kioskVersion
        },
        {
            id: 1,
            label: 'IP Address',
            text: kioskDetails?.ipAddress
        },
        {
            id: 2,
            label: 'Server Uri',
            text: ''
        },
        {
            id: 3,
            label: 'Kiosk Key',
            text: ''
        },
        {
            id: 4,
            label: 'Sync Service Key',
            text: '',
        },
        {
            id: 5,
            label: 'Kiosk Serial Number',
            text: kioskDetails?.kioskGuid
        }
    ]
    return (
        <AdminPinContainerView>
            <AdminPinInnerContainerView>
                <TechnicalInformationText data-testid="TechnicalInformationText"> {t(Strings.Admin.TechnicalInformation)} </TechnicalInformationText>
                <KioskInformationContainer>
                    {kioskInfo.map((info, index) => (
                        <KioskInformationView data-testid={`KioskInformationView${index}`} key={`${index.toString()}.${info.id}`}>
                            <KioskInfoTitleText data-testid="">{`${t(info?.label ?? '')}:`}</KioskInfoTitleText>
                            <KioskInfoValueText data-testid="">{`${t(info?.text ?? '')}`}</KioskInfoValueText>
                        </KioskInformationView>
                    ))}
                </KioskInformationContainer>
                <AdminFooterContainer data-testid="AdminFooterContainer" style={{ flexDirection: isMobile ? 'column' : 'row'}}>
                    <AdminFooterButton data-testid="CloseSoftwareButton" onClick={() => props.handleAdminAction(AdminActionType.CloseSoftware)}>
                        <CloseSoftwareTextTypography data-testid="CloseSoftwareTextTypography" variant="button">
                            <span data-testid="closeSoftwareText" className="closeSoftware-text">{t(Strings.Admin.CloseSoftware)}</span>
                            <span data-testid="closeSoftwareAsteriskText" className="asterisk-text">{'*'}</span>
                        </CloseSoftwareTextTypography>
                    </AdminFooterButton>
                    <AdminFooterInnerContainer data-testid="AdminFooterInnerContainer" style={{ flexDirection: isMobile ? 'column' : 'row'}}>
                        <AdminFooterButton data-testid="RebootSystemButton" onClick={() => props.handleAdminAction(AdminActionType.RebootSystem)}>{t(Strings.Admin.RebootSystem)}</AdminFooterButton>
                        <AdminFooterButton data-testid="ShutdownSystemButton" onClick={() => props.handleAdminAction(AdminActionType.ShutdownSystem)}>{t(Strings.Admin.ShutdownSystem)}</AdminFooterButton>
                    </AdminFooterInnerContainer>
                </AdminFooterContainer>
                <AdminFooterDisclaimerTypography data-testid="AdminFooterDisclaimerTypography" variant="button">
                    <span data-testid="disclaimerAsteriskText" className="asterisk-text">{'*'}</span>
                    <span data-testid="disclaimerText" className="closeSoftware-text">{t(Strings.Admin.disclaimer)}</span>
                </AdminFooterDisclaimerTypography>
            </AdminPinInnerContainerView>
        </AdminPinContainerView>
    )
}

export default AdminOptionsScreenContainer

