/* NoMatchingScreen.tsx */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonText } from '../../../constants/StringConstant';
import { APIFailScreenContainerView, APIFailScreenFooterText, APIFailScreenInfoText, APIFailScreenTitleText, APIFailScreenTitleView, APIFailureFooterButton, APIFailureFooterButtonView } from '../styles/StylesCommonView';
import { StyledCancelOutlinedIcon } from '../styles/StylesMatchingScreen';

interface APIFailScreenProps {
    title: string
    information: string
    isShowCloseAction: boolean
    closeAction: () => void
    tryAgainAction: () => void
}

const APIFailScreen: React.FC<APIFailScreenProps> = (props: APIFailScreenProps) => {
    const { t } = useTranslation();
    return (
        <APIFailScreenContainerView>
            <APIFailScreenTitleView>
                <StyledCancelOutlinedIcon data-testid="StyledCancelOutlinedIcon" />
                <APIFailScreenTitleText data-testid="FailureTitleText">{props.title}</APIFailScreenTitleText>
            </APIFailScreenTitleView>
            <APIFailScreenFooterText>
                <APIFailScreenInfoText data-testid="APIFailScreenInfoText"> {props.information}</APIFailScreenInfoText>
                <APIFailureFooterButtonView style={{justifyContent: props.isShowCloseAction ? 'space-between' : 'center',}}>
                    {props.isShowCloseAction ? <APIFailureFooterButton data-testid="APIFailScreenFooterCloseButton" onClick={props.closeAction}>{t(ButtonText.Close)}</APIFailureFooterButton> : null}
                    <APIFailureFooterButton data-testid="APIFailScreenFooterTryAgainButton" onClick={props.tryAgainAction}>{t(ButtonText.TryAgain)}</APIFailureFooterButton>
                </APIFailureFooterButtonView>
            </APIFailScreenFooterText>
        </APIFailScreenContainerView>
    )
}

export default APIFailScreen