import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { apiContainer } from "../../../../api/APIContainer"
import { APIRepoKeys } from "../../../../api/APIRepoKeys"
import { GetQuestionResult, Question } from "../../../../api/apiResultModels/GetQuestionResult"
import { Questionnaire } from "../../../../api/apiResultModels/KioskConfigurationResult"
import { IQuestionnaireRepository } from "../../../../api/interfaces/IQuestionnaireRepository"
import { Strings } from "../../../../constants/StringConstant"
import { handleHomeClick } from "../../../../helpers/CommonHelper"
import { isEqualIgnoreCase } from "../../../../helpers/StringHelper"
import { RouterName } from "../../../../navigation/RouterName"
import { setAPICallInProgress } from "../../../../redux/actions/GlobalAction"
import { RootState, store, useAppDispatch } from "../../../../redux/Store"
import { LoginViewModel } from "../../../../viewModels/LoginViewModel"
import Loader from "../../commonViews/Loader"
import MessageBanner from "../../commonViews/MessageBanner"
import SomethingWentWrongScreen from "../../commonViews/SomethingWentWrongScreen"
import TopBar from "../../commonViews/TopBar"
import { QuestionnaireContainerTopView, QuestionnaireContainerView } from "../../styles/StylesQuestionnaire"
import QuestionnaireListScreen from "./QuestionnaireListScreen"
import QuestionnaireScreen from "./QuestionnaireScreen"
import QuestionnaireSuccessScreen from "./QuestionnaireSuccessScreen"

const questionnaireRepository = apiContainer.get<IQuestionnaireRepository>(
    APIRepoKeys.QUESTIONNAIRE_API_REPOSITORY,
);

const QuestionnaireContainer: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [questionnaires, setQuestionnaires] = React.useState<Questionnaire[]>([])
    const [showQuestionnaireList, setShowQuestionnaireList] = React.useState<boolean>(true)
    const [showQuestionnaireScreen, setShowQuestionnaireScreen] = React.useState<boolean>(false)
    const [showSomethingWentWrongScreen, setShowSomethingWentWrongScreen] = React.useState(false)
    const [currentIndex, setCurrentIndex] = React.useState<number>(0)
    const [showQuestionnaireSuccessScreen, setShowQuestionnaireSuccessScreen] = React.useState<boolean>(false)
    const [questions, setQuestions] = React.useState<Question[]>([])
    const apiCallInProgress = useSelector((state: RootState) => state.globalSlice.apiCallInProgress);
    const location = useLocation();

    React.useEffect(() => {
        console.log("Navigated from:", location.state?.from); // Get the previous location
        if (location.state.from && isEqualIgnoreCase(location.state.from, RouterName.Home)) {
            const questionnaires = store.getState().configSlice.anonymousQuestionnaires
            setQuestionnaires(questionnaires)
        } else if (location.state.from && isEqualIgnoreCase(location.state.from, RouterName.CheckIn)) {
            //handle nonAnonymousQuestionnaires
        }
    }, [location]);


    const handleBackAction = () => {
        if (showQuestionnaireList) {
            navigate(RouterName.Home)
        } else if (showQuestionnaireScreen) {
            if (currentIndex === 0) {
                setShowQuestionnaireScreen(false)
                setShowQuestionnaireList(true)
            } else {
                setCurrentIndex(currentIndex - 1)
            }
        }
    }

    const handleCloseButtonInSuccessScreen = () => {
        navigate(RouterName.Home)
    }

    const questionTapAction = async (questionnaire: Questionnaire) => {
        if (questionnaire.questionnaireId) {
            dispatch(setAPICallInProgress(true));
            try {
                const isValidSession = await LoginViewModel().validateSession(dispatch)
                if (isValidSession) {
                    const response = await questionnaireRepository.getQuestions(`${questionnaire.questionnaireId}`)
                    const result: GetQuestionResult = JSON.parse(response)
                    setQuestions(result.data?.attributes?.questions ?? [])
                    setShowQuestionnaireScreen(true)
                    setShowQuestionnaireList(false)
                    dispatch(setAPICallInProgress(false));
                } else {
                    handleAPIError()
                }
            }
            catch (error) {
                if (error instanceof Error) {
                    handleAPIError(error)
                }
            }
        }
    }

    function handleAPIError(error?: Error) {
        console.log('error -> ', JSON.stringify(error))
        dispatch(setAPICallInProgress(false))
        setShowSomethingWentWrongScreen(true)
    }

    const onCompletionQuestionnaires = () => {
        setShowQuestionnaireScreen(false)
        setShowQuestionnaireSuccessScreen(true)
        setCurrentIndex(0)
    }

    return (
        <QuestionnaireContainerView>
            <QuestionnaireContainerTopView>
                <TopBar
                    isShowHomeButton={true}
                    isShowBackOption={!showQuestionnaireSuccessScreen}
                    handleBackClick={handleBackAction}
                    timeOutMessage={""}
                />
                {!showQuestionnaireList && !showQuestionnaireSuccessScreen ? <MessageBanner message={t(Strings.Questionnaire.TheSurgeryWouldLikeToAsk)} /> : null}
            </QuestionnaireContainerTopView>
            {showQuestionnaireList && questionnaires.length > 0 ?
                <QuestionnaireListScreen
                    questionnaires={questionnaires}
                    questionTapAction={questionTapAction}
                />
                : null
            }
            {showQuestionnaireScreen ?
                <QuestionnaireScreen
                    questions={questions}
                    onCompletionQuestionnaires={onCompletionQuestionnaires}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                />
                : null
            }
            {showQuestionnaireSuccessScreen ?
                <QuestionnaireSuccessScreen handleCloseAction={handleCloseButtonInSuccessScreen} />
                : null
            }
            {showSomethingWentWrongScreen ?
                <SomethingWentWrongScreen
                    handleCloseAction={() => handleHomeClick(dispatch, navigate)}
                />
                : null
            }
            {apiCallInProgress ? <Loader showLoading={apiCallInProgress} /> : null}
        </QuestionnaireContainerView>
    )
}

export default QuestionnaireContainer