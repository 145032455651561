/* ConfigReducer.ts */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { KioskConfigurationResult, KioskDetails, Language, Module, Organisation, Questionnaire, SessionHolder, SiteMap, SlotType } from '../../api/apiResultModels/KioskConfigurationResult';
import { defaultLanguages, defaultModules } from '../../constants/DefaultValues';

export type ConfigReducer = {
    kioskDetails: KioskDetails | undefined;
    languages: Language[];
    organisation: Organisation[];
    modules: Module[];
    slotTypes: SlotType[];
    anonymousQuestionnaires: Questionnaire[];
    nonAnonymousQuestionnaires: Questionnaire[];
    siteMaps: SiteMap[];
    sessionHolders: SessionHolder[];
    kioskDemographicDetailsTypes: KioskDemographicDetailsType[];
}

export interface KioskDemographicDetailsType {
    demographicsDetailTypeId?: number;
    name?:                     string;
}

const initialState: ConfigReducer = {
    kioskDetails: undefined,
    languages: [],
    organisation: [],
    modules: [],
    slotTypes: [],
    anonymousQuestionnaires: [],
    nonAnonymousQuestionnaires: [],
    siteMaps: [],
    sessionHolders: [],
    kioskDemographicDetailsTypes: []
}

export const configSlice = createSlice({
    name: 'config',
    initialState: initialState,
    reducers: {
        setKioskConfig: (state, action: PayloadAction<KioskConfigurationResult>) => {
            const attributes = action.payload.data?.attributes
            if (attributes) {
                state.kioskDetails = attributes.kioskDetails ?? {}
                state.languages = attributes.languages ?? defaultLanguages
                state.organisation = attributes.organisation ?? []
                state.modules = attributes.modules ?? defaultModules
                state.slotTypes = attributes.slotTypes ?? []
                state.siteMaps = [...attributes.siteMaps ?? []].map((siteMap, index) => ({ ...siteMap, id: `${index}` }))
                state.sessionHolders = attributes.sessionHolders ?? []
                const questionnaires = attributes.questionnaires ?? []
                state.anonymousQuestionnaires = (questionnaires).filter(questionnaire => questionnaire.isAnonymous)
                state.nonAnonymousQuestionnaires = (questionnaires).filter(questionnaire => !questionnaire.isAnonymous)
                state.kioskDemographicDetailsTypes = attributes.kioskDemographicDetailsTypes ?? []
            }
        },
    }
})

export default configSlice.reducer;