/* CheckInContainer.tsx */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiContainer } from '../../../../api/APIContainer';
import { MessagesParameters } from '../../../../api/apiParameterModels/MessagesParameters';
import { APIRepoKeys } from '../../../../api/APIRepoKeys';
import { BookedPatientResult, Patient } from '../../../../api/apiResultModels/BookedPatientResult';
import { GetMessageResult } from '../../../../api/apiResultModels/GetMessageResult';
import { FPatient, PatientFindResult } from '../../../../api/apiResultModels/PatientFindResult';
import { IMessagesRepository } from '../../../../api/interfaces/IMessagesRepository';
import { defaultKiosk, defaultOrg } from '../../../../constants/DefaultValues';
import { Strings } from '../../../../constants/StringConstant';
import useIdleTimer from '../../../../customHooks/useIdleTimer';
import { calculateAge, getScreenTimeOutInMilliSeconds, handleHomeClick, splitStringToArray } from '../../../../helpers/CommonHelper';
import { isEqualIgnoreCase, isNonEmpty, isNotEqualIgnoreCase } from '../../../../helpers/StringHelper';
import { KioskUIEntity } from '../../../../models/KioskUIEntity';
import { OrganizationUIEntity } from '../../../../models/OrganizationUIEntity';
import { RouterName } from '../../../../navigation/RouterName';
import { setAPICallInProgress } from '../../../../redux/actions/GlobalAction';
import { RootState, store, useAppDispatch, useAppSelector } from '../../../../redux/Store';
import { AppConfig } from '../../../../utils/AppConfig';
import { internetConnectivity } from '../../../../utils/InternetConnectivity';
import { AppointmentUIEntity, CheckInViewModel } from '../../../../viewModels/CheckInViewModel';
import { LoginViewModel } from '../../../../viewModels/LoginViewModel';
import LanguagePopupScreen from '../../commonViews/LanguagePopupScreen';
import Loader from '../../commonViews/Loader';
import MessageBanner from '../../commonViews/MessageBanner';
import SomethingWentWrongScreen from '../../commonViews/SomethingWentWrongScreen';
import TopBar from '../../commonViews/TopBar';
import { CheckInContainerScrollableView, CheckInContainerTopView, CheckInContainerView } from '../../styles/StylesCheckIn';
import MatchingContainerScreen, { MatchingComponents } from '../matchingScreen/MatchingContainerScreen';
import NoMatchingScreen from '../matchingScreen/NoMatchingScreen';
import AppointmentListScreen from './appointmentListScreen/AppointmentListScreen';
import CheckInSuccessScreen, { Message } from './CheckInSuccessScreen';
import PatientDemographicScreen from './patientInfoScreen/PatientDemographicScreen';

const messageAPIRepository = apiContainer.get<IMessagesRepository>(
    APIRepoKeys.MESSAGES_API_REPOSITORY,
);

const CheckInContainer: React.FC = () => {
    const [matchingComponents, setMatchingComponents] = React.useState<MatchingComponents>({ date: '', day: '', month: '', year: '', gender: '', surNameFirstLetter: '', postalCode: '' })
    const [showMatchingScreen, setShowMatchingScreen] = React.useState(true);
    const [showPatientInfoScreen, setShowPatientInfoScreen] = React.useState(false);
    const [showNoMatchingScreen, setShowNoMatchingScreen] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState<number>(0)
    const [showAppointmentListScreen, setShowAppointmentListScreen] = React.useState<boolean>(false)
    const [showAppointmentSuccessScreen, setShowAppointmentSuccessScreen] = React.useState<boolean>(false)
    const [showSomethingWentWrongScreen, setShowSomethingWentWrongScreen] = React.useState(false)
    const patientMatchTitle = store.getState().configSlice.kioskDetails?.patientMatchTitle
    const matchTitles = splitStringToArray(',', patientMatchTitle)
    const navigate = useNavigate();
    const [showPostCode, setShowPostCode] = React.useState<boolean>(false)
    const [postCodes, setPostCodes] = React.useState<string[]>([])
    const [selectedPatient, setSelectedPatient] = React.useState<Patient | undefined>(undefined)
    const [selectedAppointments, setSelectedAppointments] = React.useState<AppointmentUIEntity[]>([])
    const [appointments, setAppointments] = React.useState<AppointmentUIEntity[]>([])
    const [messages, setMessages] = React.useState<Message[]>([])
    const apiCallInProgress = useSelector((state: RootState) => state.globalSlice.apiCallInProgress);
    const isOnline = internetConnectivity.checkConnectivity();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const screenTimeOut = useAppSelector((state) => state.configSlice.kioskDetails?.screenTimeOut)
    const { isIdle, timeRemaining } = useIdleTimer(getScreenTimeOutInMilliSeconds(screenTimeOut), dispatch, navigate);
    const [timeOutMessageContent, setTimeOutMessageContent] = React.useState<string>('')

    const autoConfirmArrival = useAppSelector((state) => state.configSlice.kioskDetails?.autoConfirmArrival) ?? AppConfig.defaultValues.AutoConfirmArrival
    const autoConfirmMultipleArrival = useAppSelector((state) => state.configSlice.kioskDetails?.autoConfirmMultipleArrival) ?? AppConfig.defaultValues.AutoConfirmMultipleArrival
    const showDemographicDetails = useAppSelector((state) => state.configSlice.kioskDetails?.showDemographicDetails) ?? false

    React.useEffect(() => {
        if (isIdle && timeRemaining === 1000) {
            setTimeOutMessageContent(`${t(Strings.TimingOutIn)} ${timeRemaining / 1000}`)
            setTimeout(() => {
                setTimeOutMessageContent('')
            }, 1000);
        } else if (isIdle && timeRemaining && timeRemaining <= 3000) {
            setTimeOutMessageContent(`${t(Strings.TimingOutIn)} ${timeRemaining / 1000}`)
        } else {
            setTimeOutMessageContent('')
        }
    }, [dispatch, isIdle, navigate, t, timeRemaining])

    function initializeStateVariables() {
        setMatchingComponents({ date: '', day: '', month: '', year: '', gender: '', surNameFirstLetter: '', postalCode: '' });
        setShowMatchingScreen(false);
        setShowNoMatchingScreen(false);
        setShowAppointmentListScreen(false);
        setShowAppointmentSuccessScreen(false);
        setShowSomethingWentWrongScreen(false)
        setShowPatientInfoScreen(false);
        setShowPostCode(false);
        setCurrentIndex(0);
    }

    function handleBackAction() {
        if (showPostCode) {
            setShowPostCode(false);
            setShowAppointmentListScreen(false);
            setShowMatchingScreen(true);
        } else if (showPatientInfoScreen) {
            setShowPatientInfoScreen(false);
            setShowMatchingScreen(true);
        } else if (currentIndex === 0) {
            if (showAppointmentListScreen) {
                setCurrentIndex(matchTitles.length - 1);
                setShowMatchingScreen(true);
                setShowAppointmentListScreen(false);
            } else {
                navigate(RouterName.Home);
            }
        } else if (currentIndex === matchTitles.length - 1 && showAppointmentListScreen) {
            setShowAppointmentListScreen(false);
            setShowMatchingScreen(true);
        } else {
            setCurrentIndex(preValue => preValue > 0 ? preValue - 1 : preValue);
        }
    }

    async function onCompletionCheckInPreRequisite(matchingComponents: MatchingComponents): Promise<void> {
        setMatchingComponents(matchingComponents);
        initiatePatientFind(matchingComponents);
    }

    async function initiatePatientFind(matchingComponents: MatchingComponents) {
        if (!isOnline) {
            return;
        }
        dispatch(setAPICallInProgress(true));
        try {
            const isValidSession = await LoginViewModel().validateSession(dispatch)
            if (isValidSession) {
                const patientFindResult = await CheckInViewModel().initiatePatientFindAPI(matchingComponents)
                handlePatientFindResult(patientFindResult, matchingComponents)
            } else {
                //Logout the user
            }
        }
        catch (error) {
            dispatch(setAPICallInProgress(false))
            if (error instanceof Error) {
                handleAPIError(error)
            }
        }
    }

    async function handlePatientFindResult(patientFindResult: PatientFindResult, matchingComponents: MatchingComponents) {
        const patients = patientFindResult.patients ?? []
        let filteredPatients: FPatient[] = []
        if (patients.length > 0 && isNonEmpty(matchingComponents.gender) && (isEqualIgnoreCase(matchingComponents.gender.charAt(0), 'I') || isEqualIgnoreCase(matchingComponents.gender.charAt(0), 'U'))) {
            filteredPatients = [...patients].filter(patient => isNotEqualIgnoreCase(patient.sex, 'M') && isNotEqualIgnoreCase(patient.sex, 'F'))
        } else {
            filteredPatients = [...patients]
        }
        if (filteredPatients.length === 0 || (isNonEmpty(matchingComponents.postalCode) && filteredPatients.length > 1)) {
            handleNotYouAction(matchingComponents);
        } else if (filteredPatients.length === 1) {
            await initiateBookedPatient(filteredPatients[0], matchingComponents)
        } else {
            handleMultiplePatients(filteredPatients, matchingComponents)
        }
        setMatchingComponents({ ...matchingComponents, postalCode: '' })
    }

    async function initiateBookedPatient(patient: Patient, matchingComponents: MatchingComponents) {
        if (!isOnline) {
            dispatch(setAPICallInProgress(false))
            return;
        }
        try {
            const bookedPatientResult = await CheckInViewModel().initiateBookedPatientsAPI()
            handleBookedPatientResult(patient, bookedPatientResult, matchingComponents)
        }
        catch (error) {
            dispatch(setAPICallInProgress(false))
            if (error instanceof Error) {
                handleAPIError(error)
            }
        }
    }

    function handleBookedPatientResult(patient: Patient, bookedPatientResult: BookedPatientResult, matchingComponents: MatchingComponents) {
        dispatch(setAPICallInProgress(false))
        if (isNonEmpty(patient.patientId)) {
            const bookings = bookedPatientResult.bookedList?.booked?.filter(booked => booked.patient?.patientId === patient.patientId) ?? [];
            const bookedPatient = bookedPatientResult.bookedList?.booked?.find(booked => booked.patient?.patientId === patient.patientId)?.patient
            if (bookings.length > 0 && bookedPatient) {
                setSelectedPatient(bookedPatient);
                const appointmentUIEntities: AppointmentUIEntity[] = CheckInViewModel().mapToAppointmentUIEntity(bookings, bookedPatientResult.sessionHolderList?.sessionHolder ?? []);
                const availableAppointments = appointmentUIEntities.filter(appointment => !appointment.isEarlyAppointment && !appointment.isLateAppointment)
                const allowAutoCheckIn = (autoConfirmArrival && appointmentUIEntities.length === 1 && availableAppointments.length === 1) || (autoConfirmMultipleArrival && appointmentUIEntities.length === availableAppointments.length)
                if (allowAutoCheckIn) {
                    handleCheckInAPI(appointmentUIEntities, patient)
                } else {
                    setAppointments(appointmentUIEntities);
                    setShowMatchingScreen(false);
                    setShowAppointmentListScreen(true);
                }
            } else {
                handleNotYouAction(matchingComponents);
            }
        } else {
            handleNotYouAction(matchingComponents);
        }
    }

    function handleMultiplePatients(patients: Patient[], matchingComponents: MatchingComponents) {
        CheckInViewModel().initiateBookedPatientsAPI()
            .then((bookedPatientResult) => {
                const patientIDsFromPatientSearch = patients.map(patient => patient.patientId).filter(patientID => patientID !== undefined)
                const uniquePatientIDsFromPatientSearch = Array.from(new Set(patientIDsFromPatientSearch))
                const patientIDsFromBookedPatientResult = bookedPatientResult.bookedList?.booked?.map(booked => booked.patient?.patientId).filter(patientID => patientID !== undefined) ?? []
                const uniquePatientIDsFromBookedPatientResult = Array.from(new Set(patientIDsFromBookedPatientResult))
                const uniquePatientIDs = uniquePatientIDsFromBookedPatientResult.filter(name => uniquePatientIDsFromPatientSearch.includes(name));
                dispatch(setAPICallInProgress(false))
                if (uniquePatientIDs.length === 1) {
                    const patient = patients.find(patient => patient.patientId === uniquePatientIDs[0])
                    if (patient) {
                        handleBookedPatientResult(patient, bookedPatientResult, matchingComponents)
                    }
                } else if (uniquePatientIDs.length === 0 || isNonEmpty(matchingComponents.postalCode)) {
                    handleNotYouAction(matchingComponents);
                } else {
                    const postCodes = CheckInViewModel().getUniqueNonEmptyPostCodesFromPatients(patients);
                    const updatedPostCodes = CheckInViewModel().addRandomPostCodesIfNeeded(postCodes, AppConfig.defaultValues.MaximumPostCodes);
                    setPostCodes(updatedPostCodes);
                    setShowPostCode(true);
                }
            })
            .catch((error: Error) => {
                handleAPIError(error)
            })
    }

    function handlePostalCodeSelection(matchingComponents: MatchingComponents) {
        setMatchingComponents(matchingComponents);
        initiatePatientFind(matchingComponents)
    }

    async function handleCheckInAPI(selectedAppointments: AppointmentUIEntity[], patient?: Patient): Promise<void> {
        if (!isOnline) {
            return;
        }
        dispatch(setAPICallInProgress(true))
        const selectedAppointmentIDs = selectedAppointments.map(appointment => appointment.appointmentId)
        try {
            const appointmentStatusResult = await CheckInViewModel().initiateCheckInAPI(selectedAppointmentIDs)
            let appointments: AppointmentUIEntity[] = []
            appointmentStatusResult.forEach((result, index) => {
                if (result.outcome === '1') {
                    appointments.push(selectedAppointments[index])
                }
            })
            dispatch(setAPICallInProgress(false));
            initiateMessagesAPI(appointments, patient)
        }
        catch (error) {
            dispatch(setAPICallInProgress(false))
            if (error instanceof Error) {
                handleAPIError(error)
            }
        }
    }

    async function initiateMessagesAPI(appointmentUIEntities: AppointmentUIEntity[], patient?: Patient) {
        const _patient = patient ?? selectedPatient
        try {
            const selectedKiosk: KioskUIEntity = defaultKiosk
            //JSON.parse(localStorage.getItem(LocalStorageKey.SelectedKiosk) ?? "")
            const selectedOrg: OrganizationUIEntity = defaultOrg
            // JSON.parse(localStorage.getItem(LocalStorageKey.SelectedOrg) ?? "")
            let params: MessagesParameters = {
                orgId: selectedOrg.id,
                kioskId: selectedKiosk.id,
            }
            if (_patient?.patientId) {
                params = { ...params, patientId: _patient.patientId }
            }
            if (_patient?.sex) {
                params = { ...params, gender: (isEqualIgnoreCase(_patient.sex, 'M') || isEqualIgnoreCase(_patient.sex, 'F')) ? _patient.sex : 'none'}
            }
            if (_patient?.dateOfBirth) {
                params = { ...params, age: `${calculateAge(_patient.dateOfBirth)}` }
            }
            const sessionHolderIDs = appointmentUIEntities.map(appointmentUIEntity => appointmentUIEntity.sessionHolderId ?? '').filter(sessionHolderId => isNonEmpty(sessionHolderId))
            if (sessionHolderIDs.length > 0) {
                params = { ...params, sessionHolderIds: sessionHolderIDs.join(',') }
            }
            dispatch(setAPICallInProgress(true));
            const result = await messageAPIRepository.getMessages(params)
            const messageResult: GetMessageResult = JSON.parse(result)
            const messageUIEntities = messageResult.data?.map((message, index) => {
                if (message.attributes?.alterText && isNonEmpty(message.attributes.alterText)) {
                    const entity: Message = {
                        id: `${message.id ?? index}`,
                        text: message.attributes.alterText,
                        type: message.attributes.displayFormatTypeId ?? 0,
                    }
                    return entity
                }
                return undefined 
            }).filter((student): student is Message => student !== undefined) ?? []
            setMessages(messageUIEntities)
            dispatch(setAPICallInProgress(false))
            handleCheckInAPIResult(appointmentUIEntities)
        } catch (error) {
            dispatch(setAPICallInProgress(false))
            handleCheckInAPIResult(appointmentUIEntities)
            if (error instanceof Error) {
                handleAPIError(error)
            }
        }
    }

    function handleCheckInAPIResult(appointments: AppointmentUIEntity[]) {
        setSelectedAppointments(appointments);
        setShowMatchingScreen(false);
        if (showDemographicDetails) {
            setShowAppointmentListScreen(false);
            setShowPatientInfoScreen(true);
        } else {
            setShowAppointmentListScreen(false);
            setShowAppointmentSuccessScreen(true);
        }
    }

    function handleNotYouAction(matchingComponents: MatchingComponents): void {
        dispatch(setAPICallInProgress(false))
        initializeStateVariables();
        setMatchingComponents(matchingComponents);
        setShowNoMatchingScreen(true);
    }

    function handleAPIError(error?: Error) {
        console.log('error -> ', JSON.stringify(error))
        dispatch(setAPICallInProgress(false))
        initializeStateVariables();
        setShowSomethingWentWrongScreen(true)
    }

    function tryAgainAction() {
        initializeStateVariables();
        setShowMatchingScreen(true);
    }

    return (
        <CheckInContainerView data-testid="CheckInContainer">
            <CheckInContainerTopView>
                <TopBar
                    isShowHomeButton={true}
                    isShowBackOption={currentIndex >= 0 && !showAppointmentSuccessScreen && !showNoMatchingScreen && !showPatientInfoScreen && !showSomethingWentWrongScreen}
                    handleBackClick={handleBackAction}
                    timeOutMessage={timeOutMessageContent}
                />
                {showMatchingScreen && !showNoMatchingScreen ? <MessageBanner message={t(Strings.WeAreAskingTheseQuestionsToHelpIdentifyYou)} /> : null}
            </CheckInContainerTopView>
            {showMatchingScreen ?
                <CheckInContainerScrollableView>
                    <MatchingContainerScreen
                        matchingComponents={matchingComponents}
                        matchTitles={matchTitles}
                        showPostalCodes={showPostCode}
                        postalCodes={postCodes}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        onCompletionMatchingContainer={onCompletionCheckInPreRequisite}
                        onClickPostCode={handlePostalCodeSelection}
                        onNoneOfTheAboveClickOnPostCode={(matchingComponents) => {
                            setMatchingComponents(matchingComponents)
                            handleNotYouAction(matchingComponents)
                        }}
                    />
                </CheckInContainerScrollableView>
                : null
            }
            {showAppointmentListScreen ?
                <AppointmentListScreen
                    patientName={CheckInViewModel().formatPatientName(selectedPatient)}
                    appointments={appointments}
                    handleNotYouAction={() => {
                        handleNotYouAction(matchingComponents)
                    }}
                    handleCloseAction={() => handleHomeClick(dispatch, navigate)}
                    handleCheckInAction={handleCheckInAPI}
                />
                : null
            }
            {showPatientInfoScreen ?
                <PatientDemographicScreen
                    patientInfo={selectedPatient}
                    handleYesAction={() => {
                        setShowPatientInfoScreen(false)
                        setShowAppointmentSuccessScreen(true)
                    }}
                /> : null
            }
            {showNoMatchingScreen ?
                <NoMatchingScreen
                    title={t(Strings.CheckIn.WeCantFindYourAppointment)}
                    information={`${t(Strings.CheckTheInformationYouProvidedAndTryAgain)} ${t(Strings.IfTheInformationYouProvidedIsCorrectPleaseSpeakToAMemberOfOurReceptionTeam)}`}
                    matchingTitles={matchTitles}
                    matchingComponents={matchingComponents}
                    closeAction={() => handleHomeClick(dispatch, navigate)}
                    tryAgainAction={tryAgainAction}
                />
                : null
            }
            {showAppointmentSuccessScreen ?
                <CheckInSuccessScreen
                    patientName={CheckInViewModel().formatPatientName(selectedPatient)}
                    handleDoneAction={() => handleHomeClick(dispatch, navigate)}
                    selectedAppointments={selectedAppointments}
                    messages={messages}
                /> : null
            }
            {showSomethingWentWrongScreen ?
                <SomethingWentWrongScreen
                    handleCloseAction={() => handleHomeClick(dispatch, navigate)}
                />
                : null
            }
            {apiCallInProgress ? <Loader showLoading={apiCallInProgress} /> : null}
            <LanguagePopupScreen />
        </CheckInContainerView>
    );
};

export default CheckInContainer;
