import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18n from './locales/i18n';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/Store';
import initLaunchDarkly from "./manager/LaunchDarklyManager";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const renderApp = async () => {
const LDProvider = await initLaunchDarkly();
  root.render(
    <LDProvider>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </Provider>
    </LDProvider>
  );
};

renderApp();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

