import React from "react"
import { Question } from "../../../../api/apiResultModels/GetQuestionResult"
import { AppConfig } from "../../../../utils/AppConfig"
import { HeaderQuestionHeaderText } from "../../styles/StylesCommonView"
import { QuestionnaireScreenContainerView, QuestionnaireScreenTopView } from "../../styles/StylesQuestionnaire"
import QuestionnaireOptionsScreen from "./QuestionnaireOptionsScreen"
import QuestionnaireTextScreen from "./QuestionnaireTextScreen"

export interface QuestionnaireScreenProps {
    questions: Question[]
    onCompletionQuestionnaires: () => void
    currentIndex: number
    setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
}

const QuestionnaireScreen: React.FC<QuestionnaireScreenProps> = (props: QuestionnaireScreenProps) => {

    React.useEffect(() => {
        if (props.currentIndex === props.questions.length) {
            props.onCompletionQuestionnaires()
        }
    }, [props.currentIndex, props])

    const setAnswers = () => {
        if (props.currentIndex === props.questions.length - 1) {
            props.onCompletionQuestionnaires()
        } else {
            props.setCurrentIndex(props.currentIndex + 1)
        }
    }
    const question = props.questions[props.currentIndex]
    return (
        <QuestionnaireScreenContainerView>
            <QuestionnaireScreenTopView sx={{ textAlign: question.questionType === 4 ? 'center' : 'left' }}>
                <HeaderQuestionHeaderText>{question.questionText}</HeaderQuestionHeaderText>
            </QuestionnaireScreenTopView>
            {question.questionType === 4 && (question.questionAnswerOptions?.length ?? 0) > 0 ?
                <QuestionnaireOptionsScreen
                    options={question.questionAnswerOptions ?? []}
                    tapAction={setAnswers}
                />
                : null
            }
            {question.questionType === 2 ?
                <QuestionnaireTextScreen
                    text={''}
                    tapAction={setAnswers}
                    keyboardType={'numeric'}
                    charLimit={question.optionCharLimit ?? AppConfig.defaultValues.MaxAllowedCharacter}
                />
                : null
            }
            {question.questionType === 1 ?
                <QuestionnaireTextScreen
                    text={''}
                    tapAction={setAnswers}
                    keyboardType={'text'}
                    charLimit={question.optionCharLimit ?? AppConfig.defaultValues.MaxAllowedCharacter}
                />
                : null
            }
        </QuestionnaireScreenContainerView>
    )
}

export default QuestionnaireScreen