import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { apiContainer } from "../api/APIContainer";
import { APIRepoKeys } from "../api/APIRepoKeys";
import { TokenResult } from "../api/apiResultModels/TokenResult";
import { ITokenRepository } from "../api/interfaces/ITokenRepository";
import { delay } from "../helpers/CommonHelper";
import { isEmpty } from "../helpers/StringHelper";
import { setTokenDetails } from "../redux/actions/TokenAction";
import { store } from "../redux/Store";
import { AppConfig } from "../utils/AppConfig";

export const LoginViewModel = () => {

    const tokenRepository = apiContainer.get<ITokenRepository>(
        APIRepoKeys.TOKEN_API_REPOSITORY,
    );

    const validateSession = (dispatch: Dispatch<UnknownAction>): Promise<boolean> => {
        return new Promise((resolve) => {
            if (LoginViewModel().isUserSessionValid()) {
                resolve(true)
                return
            }
            fetchTokenAndValidate(dispatch)
                .then(isValid => resolve(isValid))
                .catch(() => resolve(false));
        })
    };
    const fetchTokenAndValidate = async (dispatch: Dispatch<UnknownAction>): Promise<boolean> => {
        try {
            const response = await LoginViewModel().geToken();
            const tokenResult: TokenResult = JSON.parse(response);

            if (tokenResult.access_token && tokenResult.expires_in) {
                dispatch(setTokenDetails(tokenResult));
                await delay(500);
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    };

    const geToken = (): Promise<string> => {
        return tokenRepository.getToken();
    };

    function isUserSessionValid() {
        const tokenCreatedDateTimeString = store.getState().tokenSlice.tokenCreatedDateTime;
        if (isEmpty(tokenCreatedDateTimeString)) {
            return false
        }
        const tokenCreatedDateTime = new Date(tokenCreatedDateTimeString)
        const tokenExpiresIn = store.getState().tokenSlice.tokenExpiresIn;
        if (tokenCreatedDateTime && tokenExpiresIn) {
            const tokenExpireDuration = tokenExpiresIn - AppConfig.defaultValues.QuarterHourInSeconds
            const tokenCreatedDateTimeInSecond = ((new Date()).getTime() - tokenCreatedDateTime.getTime()) / 1000;
            return tokenExpireDuration > tokenCreatedDateTimeInSecond
        } else {
            return false
        }
    }

    return {
        validateSession,
        geToken,
        isUserSessionValid
    }
}
