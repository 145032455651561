
import { injectable } from "inversify";
import { getAccessToken } from "../../utils/ApiRequestUtil";
import { AppConfig } from "../../utils/AppConfig";
import { DD_APIEndPointName } from "../../utils/DDAPIEndpointName";
import { APIClient } from "../APIClient";
import { IQuestionnaireRepository } from "../interfaces/IQuestionnaireRepository";

@injectable()
export class QuestionnaireRepository implements IQuestionnaireRepository {
    getQuestionnaires(): Promise<string> {
        return new Promise((resolve, reject) => {
            const accessToken = `Bearer ${getAccessToken()}`
            APIClient.getRequest(
                DD_APIEndPointName.Questionnaire_API_Endpoint,
                AppConfig.Questionnaire, accessToken)
                .then(response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    getQuestions(questionnaireID: string): Promise<string> {
        const params = { questionnaireId: questionnaireID };
        return new Promise((resolve, reject) => {
            const accessToken = `Bearer ${getAccessToken()}`
            APIClient.getRequest(
                DD_APIEndPointName.Questionnaire_API_Endpoint,
                AppConfig.Questionnaire,
                accessToken,
                params)
                .then(response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

}