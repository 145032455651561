import { ButtonText } from "../../../constants/StringConstant";
import deleteKey from '../../../images/delete_left_black_icon.png';
import { DeleteKeyTextButton, KeyboardContainer, KeyButton, NumberPadContainer } from "../styles/StylesQuestionnaire";

interface NumericKeyboardComponentProps {
    onKeyClick: (number: string) => void;
    onDeleteClick: () => void;
    onCloseClick: () => void;
}

const NumericKeyboardComponent: React.FC<NumericKeyboardComponentProps> = (props: NumericKeyboardComponentProps) => {
    
    return (
        <KeyboardContainer>
            <NumberPadContainer sx={{maxWidth: '15%'}}>
                {[7, 8, 9, 4, 5, 6, 1, 2, 3].map((num) => (
                    <KeyButton key={num} onClick={() => props.onKeyClick(String(num))}>{num}</KeyButton>
                ))}
                <DeleteKeyTextButton sx={{ gridColumn: 'span 2', }} onClick={props.onDeleteClick}>
                <img style={{ width: '50%', aspectRatio: '1.2' }} src={deleteKey} alt="Custom icon" />
                </DeleteKeyTextButton> 
                <KeyButton key={'0'} onClick={() => props.onKeyClick('0')}>{'0'}</KeyButton>

                <KeyButton key={'+'} onClick={() => props.onKeyClick('+')}>{'+'}</KeyButton>
                <KeyButton sx={{ gridColumn: 'span 2', }} onClick={props.onCloseClick}>
                    {ButtonText.CLOSE}
                </KeyButton>            
            </NumberPadContainer>
        </KeyboardContainer>
    );
};

export default NumericKeyboardComponent