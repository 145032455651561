import { Container } from "inversify";
import "reflect-metadata";
import { APIRepoKeys } from "./APIRepoKeys";
import { AppointmentRepository } from "./repositories/AppointmentRepository";
import { KioskRepository } from "./repositories/KioskRepository";
import { MessagesRepository } from "./repositories/MessagesRepository";
import { PatientRepository } from "./repositories/PatientRepository";
import { QuestionnaireRepository } from "./repositories/QuestionnaireRepository";
import { TokenRepository } from "./repositories/TokenRepository";

const _container = new Container();
_container.bind(APIRepoKeys.TOKEN_API_REPOSITORY).to(TokenRepository).inSingletonScope();
_container.bind(APIRepoKeys.PATIENT_API_REPOSITORY).to(PatientRepository).inSingletonScope();
_container.bind(APIRepoKeys.APPOINTMENT_API_REPOSITORY).to(AppointmentRepository).inSingletonScope();
_container.bind(APIRepoKeys.KIOSK_API_REPOSITORY).to(KioskRepository).inSingletonScope();
_container.bind(APIRepoKeys.QUESTIONNAIRE_API_REPOSITORY).to(QuestionnaireRepository).inSingletonScope();
_container.bind(APIRepoKeys.MESSAGES_API_REPOSITORY).to(MessagesRepository).inSingletonScope();
export const apiContainer = _container;

