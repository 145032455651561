/* BookAppointmentTypeSelection.tsx */

import { Grid } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Strings } from '../../../../../constants/StringConstant'
import { store } from '../../../../../redux/Store'
import HeaderQuestion from '../../../commonViews/HeaderQuestion'
import { AppointmentTypeGridButton, BookAppointmentTypeSelectionContainer, BookAppointmentTypeSelectionDividerView, BookAppointmentTypeSelectionHeaderQuestionView } from '../../../styles/StylesBookAppointment'
import BookAppointmentTypeSelectionHeaderView from './BookAppointmentTypeSelectionHeaderView'

interface BookAppointmentTypeSelectionProps {
    patientName: string,
    handleNotYouAction: () => void
    handleSlotTypeAction: (slotTypeId: string) => void
}

const BookAppointmentTypeSelection: React.FC<BookAppointmentTypeSelectionProps> = (props: BookAppointmentTypeSelectionProps) => {
    const { t } = useTranslation();
    const slotTypes = store.getState().configSlice.slotTypes

    return (
        <BookAppointmentTypeSelectionContainer>
            <BookAppointmentTypeSelectionHeaderView patientName={props.patientName} handleNotYouAction={props.handleNotYouAction} />
            <BookAppointmentTypeSelectionDividerView />
            <BookAppointmentTypeSelectionHeaderQuestionView>
                <HeaderQuestion headerText={t(Strings.BookAppointment.SelectAppointmentType)} subHeaderText={''} />
            </BookAppointmentTypeSelectionHeaderQuestionView>
            <Grid container columnGap={5} rowGap={5} marginTop={3} justifyContent={'center'}>
                {slotTypes.map((slotType, index) => {
                    return (
                        <Grid item key={`${index}.${slotType.SlotTypeId}`}>
                            <AppointmentTypeGridButton
                                onClick={() => {
                                    props.handleSlotTypeAction(`${slotType.SlotTypeId}`)
                                }}
                                variant="contained"
                            >
                                {slotType.Description}
                            </AppointmentTypeGridButton>
                        </Grid>
                    )
                })}
            </Grid>
        </BookAppointmentTypeSelectionContainer>
    )

}

export default BookAppointmentTypeSelection