import React from "react"
import { QuestionAnswerOption } from "../../../../api/apiResultModels/QuestionnaireResult"
import { isNonEmpty } from "../../../../helpers/StringHelper"
import { QuestionnaireScreenOptionsView, QuestionOptionButton } from "../../styles/StylesQuestionnaire"

export interface QuestionnaireOptionsScreenProps {
    options: QuestionAnswerOption[]
    tapAction: () => void
}

const QuestionnaireOptionsScreen: React.FC<QuestionnaireOptionsScreenProps> = (props: QuestionnaireOptionsScreenProps) => {
    const options = props.options.filter(option => isNonEmpty(option.optionValue))
    return (
        <QuestionnaireScreenOptionsView>
            {options.map((option, index) => {
                return (
                    <QuestionOptionButton
                        key={`${index}.${option}`}
                        onClick={props.tapAction}
                        variant="contained"
                    >
                        {option.optionValue}
                    </QuestionOptionButton>
                )
            })}
        </QuestionnaireScreenOptionsView>
    )
}

export default QuestionnaireOptionsScreen