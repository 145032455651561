
import { useTranslation } from "react-i18next";
import { ButtonText, Strings } from "../../../../constants/StringConstant";
import { CloseButton, QuestionnaireSuccessInfoView, QuestionnaireSuccessScreenContainerView, QuestionnaireSuccessTankYouDescriptionText, QuestionnaireSuccessTankYouSubText, QuestionnaireSuccessTankYouText } from "../../styles/StylesQuestionnaire";

export interface QuestionnaireSuccessScreenProps {
    handleCloseAction: () => void
}

const QuestionnaireSuccessScreen: React.FC<QuestionnaireSuccessScreenProps> = (props: QuestionnaireSuccessScreenProps) => {
    const { t } = useTranslation();
    return (
        <QuestionnaireSuccessScreenContainerView>
            <QuestionnaireSuccessInfoView>
                <QuestionnaireSuccessTankYouText> {t(Strings.Questionnaire.ThankYou)} </QuestionnaireSuccessTankYouText>
                <QuestionnaireSuccessTankYouSubText> {t(Strings.Questionnaire.ThankYouYourAnswersHaveBeenSaved)} </QuestionnaireSuccessTankYouSubText>
                <QuestionnaireSuccessTankYouDescriptionText> {t(Strings.Questionnaire.YourFeedbackIsImportantToUs)} </QuestionnaireSuccessTankYouDescriptionText>
            </QuestionnaireSuccessInfoView>
            <CloseButton
                onClick={props.handleCloseAction}
            >
                {ButtonText.Close}
            </CloseButton>
        </QuestionnaireSuccessScreenContainerView>
    )
}

export default QuestionnaireSuccessScreen