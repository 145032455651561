import React from "react"
import { useTranslation } from "react-i18next"
import { Questionnaire } from "../../../../api/apiResultModels/KioskConfigurationResult"
import { Strings } from "../../../../constants/StringConstant"
import HeaderQuestion from "../../commonViews/HeaderQuestion"
import { QuestionnaireListButton, QuestionnaireListContainerView, QuestionnaireListInnerContainer, QuestionnaireListView } from "../../styles/StylesQuestionnaire"

interface QuestionnaireListScreenProps {
    questionnaires: Questionnaire[]
    questionTapAction: (questionnaire: Questionnaire) => void
}
const QuestionnaireListScreen: React.FC<QuestionnaireListScreenProps> = (props: QuestionnaireListScreenProps) => {
    const { t } = useTranslation();
    return (
        <QuestionnaireListContainerView>
            <QuestionnaireListInnerContainer>
                <HeaderQuestion headerText={t(Strings.Questionnaire.SelectQuestionnaire)} subHeaderText={""} justifyContent={'left'}/>
                <QuestionnaireListView>
                    {props.questionnaires.map((questionnaire, index) => {
                        return (
                            <QuestionnaireListButton key={`${index}.${questionnaire.questionnaireId}`} onClick={() => props.questionTapAction(questionnaire)}>
                                {`${questionnaire.title}`}
                            </QuestionnaireListButton>
                        )
                    })}
                </QuestionnaireListView>
            </QuestionnaireListInnerContainer>
        </QuestionnaireListContainerView>
    )
}

export default QuestionnaireListScreen