import { KioskDemographicDetailsType, Language, Module } from "../api/apiResultModels/KioskConfigurationResult"
import { KioskUIEntity } from "../models/KioskUIEntity"
import { OrganizationUIEntity } from "../models/OrganizationUIEntity"

export const defaultLanguages: Language[] = [
    {
        "languageId": 1,
        "languageCode": "en",
        "languageName": "English",
        "order": 1,
        "translationRefId": 1
    },
    {
        "languageId": 23,
        "languageCode": "gu",
        "languageName": "Gujarati",
        "order": 11,
        "translationRefId": 102
    },
    {
        "languageId": 24,
        "languageCode": "mis",
        "languageName": "Iraqi",
        "order": 14,
        "translationRefId": 103
    },
    {
        "languageId": 25,
        "languageCode": "ku",
        "languageName": "Kurdish",
        "order": 16,
        "translationRefId": 104
    },
    {
        "languageId": 26,
        "languageCode": "fa",
        "languageName": "Farsi",
        "order": 7,
        "translationRefId": 105
    },
    {
        "languageId": 27,
        "languageCode": "ta",
        "languageName": "Tamil",
        "order": 26,
        "translationRefId": 106
    },
    {
        "languageId": 28,
        "languageCode": "hu",
        "languageName": "Hungarian",
        "order": 13,
        "translationRefId": 76
    }
]

export const defaultModules: Module[] = [
    {
        "moduleName": "Arrival",
        "translationRefId": 1,
        "moduleId": 1
    },
    {
        "moduleName": "Survey",
        "translationRefId": 2,
        "moduleId": 2
    },
    {
        "moduleName": "Site Map",
        "translationRefId": 3,
        "moduleId": 3
    },
    {
        "moduleName": "MakeAppointment",
        "translationRefId": 4,
        "moduleId": 4
    }
]

export const defaultSelectedDemographicDetailsList: KioskDemographicDetailsType[] = [
    {
        "name": "Email Address"
    },
    {
        "name": "Mobile number"
    },
    {
        "name": "Name"
    },
    {
        "name": "Postcode"
    },
    {
        "name": "Home telephone number"
    }
]

export const defaultKiosk: KioskUIEntity = {
    id: "2002",
    name: "50002",
    title: "Welcome 50002",
    ipAddress: "",
    version: "",
    guid: "86431660-57df-4623-b20b-646194115b92",
}

export const defaultOrg: OrganizationUIEntity = {
    id: "2",
    name: '',
    guid: '',
}