import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export class AnalyticsManager {
    public static readonly initializeDataDog = () => {
        const DDApplicationID = process.env.REACT_APP_DATADOG_APPLICATIONID as string;  
        const DDEnv = process.env.REACT_APP_DD_ENV as string;
        const clientToken = process.env.REACT_APP_DATADOG_CLIENTTOKEN as string;
        datadogRum.init({
            applicationId: DDApplicationID,//'b832f044-4fb7-4a34-9f22-c14e5795e959',
            clientToken: clientToken,
            // `site` refers to the Datadog site parameter of your organization
            // see https://docs.datadoghq.com/getting_started/site/
            site: 'datadoghq.eu',
            service: 'automated-arrivals-kiosk-app',
            env: DDEnv,
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0',
            sessionSampleRate: 100,
            startSessionReplayRecordingManually: true,
            trackUserInteractions: false,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
        });
        datadogLogs.init({
            clientToken: clientToken,
            site: 'datadoghq.eu',
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
          })
    };
    
    public static readonly setGlobalContextforSession = (propertyKey: string, value:any) => {
        datadogRum.setGlobalContextProperty(propertyKey, value);
    };

    public static readonly logDDError = (errorLog: string, params: any) => {
        datadogLogs.logger.error(errorLog, params);
    }

}