import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import AdminContainerScreen from '../view/web/screens/admin/AdminContainerScreen';
import BookAppointmentContainer from '../view/web/screens/bookAppointment/BookAppointmentContainer';
import CheckInContainer from '../view/web/screens/checkIn/CheckInContainer';
import HomeScreen from '../view/web/screens/home/HomeScreen';
import QuestionnaireContainer from '../view/web/screens/questionnaires/QuestionnaireContainer';
import SiteMapScreen from '../view/web/screens/siteMapScreen/SiteMapScreen';
import { RouterName } from './RouterName';

const RouterComponent: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route
                    path={RouterName.Home}
                    element={<HomeScreen />}
                />
                <Route
                    path={RouterName.CheckIn}
                    element={<CheckInContainer />}
                />
                <Route
                    path={RouterName.BookAppointment}
                    element={<BookAppointmentContainer />}
                />
                <Route
                    path={RouterName.SiteMap}
                    element={<SiteMapScreen />}
                />
                <Route
                    path={RouterName.Admin}
                    element={<AdminContainerScreen />}
                />
                <Route
                    path={RouterName.Questionnaire}
                    element={<QuestionnaireContainer />}
                />
            </Routes>
        </Router>
    );
};

export default RouterComponent;
