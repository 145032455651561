import React from "react"
import { ButtonText } from "../../../../constants/StringConstant"
import { isEmpty } from "../../../../helpers/StringHelper"
import { AppConfig } from "../../../../utils/AppConfig"
import NumericKeyboardComponent from "../../commonViews/NumericKeyboardComponent"
import TextKeyboardComponent from "../../commonViews/TextKeyboardComponent"
import { QuestionContinueButton, QuestionnaireKeyboardContainerView, QuestionnaireScreenTextInnerView, QuestionnaireScreenTextView, QuestionnaireTextView } from '../../styles/StylesQuestionnaire'

export interface QuestionnaireTextScreenProps {
    text: string
    keyboardType: 'text' | 'numeric'
    tapAction: () => void
    charLimit: number
}

const QuestionnaireTextScreen: React.FC<QuestionnaireTextScreenProps> = (props: QuestionnaireTextScreenProps) => {
    const [text, setText] = React.useState<string>(props.text)
    const [showKeyboard, setShowKeyboard] = React.useState<boolean>(true)

    function onKeyClick(number: string) {
        if(text.length < (props.charLimit === 0 ? AppConfig.defaultValues.MaxAllowedCharacter : props.charLimit)) {
            setText(`${text}${number}`)
        }
    }

    function onDeleteClick() {
        setText(text.slice(0, -1))
    }

    return (
        <QuestionnaireScreenTextView>
            <QuestionnaireScreenTextInnerView>
                <QuestionnaireTextView onClick={() => setShowKeyboard(true)}>
                    {text}
                </QuestionnaireTextView>
                <QuestionContinueButton disabled={isEmpty(text)} onClick={props.tapAction}>{ButtonText.Continue}</QuestionContinueButton>
            </QuestionnaireScreenTextInnerView>
            {showKeyboard && props.keyboardType === 'numeric'  ?
                <QuestionnaireKeyboardContainerView>
                    <NumericKeyboardComponent
                        onKeyClick={onKeyClick}
                        onDeleteClick={onDeleteClick}
                        onCloseClick={() => setShowKeyboard(false)}
                    />
                </QuestionnaireKeyboardContainerView>
                : false
            }
            {showKeyboard && props.keyboardType === 'text' ?
                <QuestionnaireKeyboardContainerView>
                    <TextKeyboardComponent
                        onKeyClick={onKeyClick}
                        onDeleteClick={onDeleteClick}
                        onCloseClick={() => setShowKeyboard(false)}
                        isShiftOn={isEmpty(text)} 
                    />
                </QuestionnaireKeyboardContainerView>
                : false
            }
        </QuestionnaireScreenTextView>
    )
}

export default QuestionnaireTextScreen