import { injectable } from "inversify";
import { store } from "../../redux/Store";
import { getAdminAccessToken } from "../../utils/ApiRequestUtil";
import { AppConfig } from "../../utils/AppConfig";
import { DD_APIEndPointName } from "../../utils/DDAPIEndpointName";
import { APIClient } from "../APIClient";
import { IKioskRepository } from "../interfaces/IKioskRepository";


@injectable()
export class KioskRepository implements IKioskRepository {
    getKioskList(): Promise<string> {
        return new Promise((resolve, reject) => {
            const accessToken = `Bearer ${getAdminAccessToken()}`
            APIClient.getRequest(
                DD_APIEndPointName.kioskList_API_Endpoint,
                AppConfig.kiosk, accessToken)
                .then(response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    getKioskConfiguration(kioskId: string): Promise<string> {
        const params = {
            KioskId: kioskId,
        };
        return new Promise((resolve, reject) => {
            const accessToken = `Bearer ${store.getState().tokenSlice.accessToken}`
            APIClient.getRequest(
                DD_APIEndPointName.kioskConfiguration_API_Endpoint,
                AppConfig.kioskConfiguration,
                accessToken,
                params)
                .then(response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    getKioskDetail(kioskId: string): Promise<string> {
        const params = { KioskId: kioskId };
        const accessToken = `Bearer ${getAdminAccessToken()}`
        return new Promise((resolve, reject) => {
            APIClient.getRequest(
                DD_APIEndPointName.kioskDetail_API_Endpoint,
                AppConfig.kioskDetail,
                accessToken,
                params)
                .then(response => {
                    resolve(response);
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
}