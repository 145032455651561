
export const SizeConstant = {

    TopBar: {
        height: 100,
        heightMobile: 70,
    },
    Org: {
        gridWidth: 200,
        gridHeight: 70,
    },
    BookAppointment: {
        AppointmentTypeGrid: {
            width: 200,
            height: 70,
        },
        SlotSelection: {
            dateStripHeight: 80,
            divider: 5,
            SlotTimeButton: {
                width: 100,
                height: 50,
            }
        }
    },
    Matching: {
        Surname: {
            gridWidth: 90,
            gridHeight: 70,
        },
        Day: {
            gridWidth: 90,
            gridHeight: 70,
        },
        Month: {
            gridWidth: 200,
            gridHeight: 70,
        },
        Year: {
            gridWidth: 140,
            gridHeight: 70,
        },
        Gender: {
            gridWidth: 200,
            gridHeight: 70,
        },
        Postal: {
            gridWidth: 200,
            gridHeight: 70,
        },
    },
    Admin: {
        FooterButton: {
            gridWidth: 210,
            gridHeight: 70,
        },
    }

} as const;