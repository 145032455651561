
export const AppConfig = {
    apiTimeout: 60000,
    Token_Endpoint: '79809fbe-0297-4f3c-848d-97f841b62a7a/B2C_1A_ClientCredentials/oauth2/v2.0/token',
    PatientSearch_API_Endpoint: 'PatientSearch',
    BookedPatients_API_Endpoint: 'BookedPatients',
    SetAppointmentStatus_API_Endpoint: 'SetAppointmentStatus',
    GetAppointmentSessions: 'GetAppointmentSessions',
    GetSessionSlots: 'GetSlotsForSession',
    BookAppointment: 'BookAppointment',
    Questionnaire: 'Questionnaire',
    kiosk: 'kiosk',
    Message: 'Messages/Info',
    kioskConfiguration: 'Configuration',
    kioskDetail: 'Kiosk/GetKioskDetails',
    defaultValues: {
        DemographicDetailsDuration: 180,
        EarlyArrival: 60,
        LateArrival: 10,
        ScreenTimeOut: 30,
        ShowDoctorDelay: true,
        AllowUnTimed: false,
        AutoConfirmArrival: true,
        AutoConfirmMultipleArrival: true,
        BookingReasonMaxAllowedCharacter: 35,
        MaximumPostCodes: 6,
        AdminLoginPinSize: 4,
        ServiceName: "AAX-App-ClientCredentials",
        QuarterHourInSeconds: 900,
        MaxAllowedCharacter: 30,
    }
}